<template>
  <v-app>
    <Navigation :drawer.sync="drawer"/>
    <AppBar @drawerChange="drawer = !drawer"/>
    <v-main class="natural">
      <router-view />
    </v-main>
    <v-footer>
     <InternetSnackBar v-model="internetPopup" :online="online"/>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from '@/components/mainlayout/Navigation'
import InternetSnackBar from '@/components/common/InternetSnackBar'
import AppBar from '@/components/mainlayout/AppBar'

export default {
  components: {
    Navigation,
    AppBar,
    InternetSnackBar
  },
  data () {
    return {
      online: false,
      internetPopup: false,
      drawer: false
    }
  },
  mounted () {
    if (this.$vuetify.breakpoint.mobile) {
      this.drawer = false
    } else {
      this.drawer = true
    }
  },
  created () {
    window.addEventListener('offline', this.onOffline)
    window.addEventListener('online', this.onOnline)
  },
  destroyed () {
    window.removeEventListener('offline', this.onOffline)
    window.removeEventListener('online', this.onOnline)
  },
  methods: {
    onOffline () {
      console.log('offline')
      this.online = false
      this.internetPopup = true
    },
    onOnline () {
      console.log('online')
      this.online = true
      this.internetPopup = false
    }
  }
}
</script>

<style>

</style>
