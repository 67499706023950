
import { getAssets, patchAsset, getAssetType, getBrands, getModels, getSuppliers, getAssetBeacon, getAssetStock, postAssetBeacon, getAssetImage, getAssetStatus, postAsset, postModels, postAssetType, postBrands, postSuppliers, postAssetImage, getBulkPosition, getRooms, deleteAssetBeacon, getDistribute, postPrepareAsset, getInternalRooms, postInternalPositions, postAssetUtilization, deleteAssetUtilization, getAssetUtilization, getTimePerLocation } from '@/api/'
import * as alasql from 'alasql'

function select (data, key, item) {
  const selectedData = {}
  selectedData[key] = data[key].map(function (d) { return d[item] })
  return selectedData
}

const groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

const groupByAssetTypeId = groupBy('assetTypeId')

const state = () => ({
  asset: {},
  assetBeacon: {},
  assetUtilization: {},
  assets: {},
  assetsWithLocation: {},
  assetStock: [],
  status: [],
  availableAssets: [],
  previousPage: [],
  pageSize: 0,
  totalItems: 0,
  page: 0,
  assetTypes: [],
  brands: [],
  models: [],
  suppliers: [],
  searchAssetId: '',
  options: {},
  defaultOptions: {},
  filter: {
    assetId: '',
    brandId: '',
    modelId: '',
    supplierId: '',
    assetTypeId: '',
    riskLevel: '',
    statusId: '',
    isPooling: '',
    isTracking: 1,
    active: true
  },
  keyword: '',
  rooms: []
})

const mutations = {
  SET_ASSET_LIST_WITH_LOCATION (state, { assets, location }) {
    const rooms = []
    location.forEach((element) => {
      state.rooms.forEach((subEl) => {
        if (subEl.id === element.roomId) {
          element = { ...element, ...subEl }
        }
      })
      delete element.id
      rooms.push(element)
    })
    state.positions = rooms
    for (let i = 0; i < assets.items.length; i++) {
      rooms.forEach((element) => {
        if (element.trackingId === assets.items[i].mac) {
          assets.items[i] = { ...assets.items[i], ...element }
        }
      })
    }
    state.assetsWithLocation = assets
  },
  SET_ASSET (state, data) {
    state.asset = data
  },
  SET_ASSET_BEACON (state, data) {
    state.assetBeacon = data
  },
  SET_ASSET_UTILIZATION (state, data) {
    state.assetUtilization = data
  },
  SET_STATUS (state, data) {
    state.status = data
  },
  SET_SEARCH_ASSET_ID (state, data) {
    state.searchAssetId = data
  },
  SET_ASSETS_LIST (state, data) {
    state.assets = data
  },
  SET_ASSET_STOCK (state, data) {
    const filteredData = data.sort((a, b) => {
      if (a.isFocus < b.isFocus) {
        return 1
      }
      if (a.isFocus > b.isFocus) {
        return -1
      }
      return a.brand.localeCompare(b.brand)
    }) // .filter(el => el.assetTypeId === 3 || el.assetTypeId === 6 || el.assetTypeId === 8 || el.assetTypeId === 14 || el.assetTypeId === 13 || el.assetTypeId === 12)
    const groupData = groupByAssetTypeId(filteredData)
    const items = []

    Object.keys(groupData).forEach((groupByAssetTypeId) => {
      const assetType = groupData[groupByAssetTypeId]
      const assetTypeNameTH = assetType[0].assetTypeNameTH
      const assetTypeNameEN = assetType[0].assetTypeNameEN
      // Create a group
      items.push({ header: `${assetTypeNameTH}/${assetTypeNameEN}` })
      // Add all the items followed by category header
      items.push(...assetType)
      items.push({ divider: true })
    })
    state.assetStock = items
    // console.log(groupByAssetTypeNameTH(state.assetStock))
  },
  SET_AVAILABLE_ASSET_LIST (state, data) {
    state.availableAssets = data
  },
  SET_TOTAL_ITEMS (state, data) {
    state.totalItems = (state.page * data) + 1
  },
  SET_PREVIOUS_PAGE (state) {
    state.previousPage = state.assets
  },
  SET_ASSET_FROM_PREVIOUS_PAGE (state) {
    state.assets = state.previousPage
  },
  SET_PAGE (state, data) {
    state.page = data
  },
  SET_PAGE_SIZE (state, data) {
    state.pageSize = data
  },
  SET_ASSET_TYPES (state, data) {
    state.assetTypes = data
  },
  SET_BRANDS (state, data) {
    state.brands = data
  },
  SET_MODELS (state, data) {
    state.models = data.sort((a, b) => {
      if (a.isFocus < b.isFocus) {
        return 1
      }
      if (a.isFocus > b.isFocus) {
        return -1
      }
      return 0
    })
  },
  SET_SUPPLIERS (state, data) {
    state.suppliers = data
  },
  SET_OPTIONS (state, data) {
    state.options = data
  },
  SET_DEFAULT_OPTIONS (state, data) {
    state.defaultOptions = data
  },
  SET_FILTER (state, { element, value }) {
    state.filter[element] = value
  },
  SET_KEYWORD (state, data) {
    state.keyword = data
  },
  SET_ALL_ROOMS (state, data) {
    state.rooms = data
  },
  SET_FILTER_TO_DEFAULT (state) {
    state.keyword = ''
    state.filter = {
      brandId: '',
      modelId: '',
      supplierId: '',
      assetTypeId: '',
      riskLevel: '',
      statusId: '',
      assetId: '',
      isPooling: '',
      isTracking: ''
    }
  }
}

const actions = {
  SET_ASSET ({ commit }, data) {
    commit('SET_ASSET', data)
  },
  GET_BORROWED_DEPARTMENT ({ commit, state, rootState }, data) {
    return new Promise((resolve, reject) => {
      getDistribute({ tenantId: rootState.User.tenant.tenantId, pageSize: 1, pageNo: 1, sortBy: '', sortDesc: '', isReturn: 0, assetId: data.assetId, keyword: '', distributionId: '', bookingId: '', brandId: '', modelId: '', borrowDepartmentId: '', isOverDowntime: '', startDate: '', endDate: '', returnStartDate: '', returnEndDate: '', assetTypeId: '' }, message => {
        if (message.data.items.length > 0) {
          resolve(message.data.items[0])
        } else {
          resolve({})
        }
      }, error => {
        reject(error)
      })
    })
  },
  GET_ALL_ROOM ({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        if (state.rooms.length === 0) {
          getRooms({ tenantId: rootState.User.tenant.tenantId, roomId: '' }, message => {
            commit('SET_ALL_ROOMS', message.data.content)
            resolve(message.data.content)
          }, error => {
            reject(error)
          })
        } else {
          resolve()
        }
      } else {
        getInternalRooms({ tenantId: rootState.User.tenant.tenantId }, message => {
          commit('SET_ALL_ROOMS', message.data.value)
          resolve(message.data.value)
        }, error => {
          reject(error)
        })
      }
    })
  },
  GET_BULK_POSTION ({ commit, state, rootState }, data) {
    const body = {
      trackingId: data
    }
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getBulkPosition({ tenantId: rootState.User.tenant.tenantId }, body, message => {
          resolve(message.data)
        }, error => {
          reject(error)
        })
      } else {
        postInternalPositions({ tenantId: rootState.User.tenant.tenantId }, body, message => {
          resolve(message.data.value)
        }, error => {
          reject(error)
        })
      }
    })
  },
  GET_TIME_PER_LOCATION ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      getTimePerLocation({ tenantId: rootState.User.tenant.tenantId, mac: data.mac, dayRange: data.dayRange }, message => {
        resolve(alasql('SELECT rowKey as location, ROUND(SUM(Time)/60,2) as totalTime FROM ? group by rowKey', [message.data.value]))
        // resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  GET_ASSET ({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      getAssets({ tenantId: rootState.User.tenant.tenantId, assetId: options.assetId, mac: options.mac, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: options.sortDesc, active: options.active, keyword: options.keyword, brandId: options.brandId, modelId: options.modelId, supplierId: options.supplierId, assetTypeId: options.assetTypeId, riskLevel: options.riskLevel, statusId: options.statusId, isPooling: options.isPooling, isTracking: options.isTracking }, message => {
        if (message.data.items.length > 0) {
          commit('SET_ASSET', message.data.items[0])
          resolve(message.data.items[0])
        } else {
          reject(message.data)
        }
      }, error => {
        reject(error)
      })
    })
  },
  GET_ASSET_BEACON ({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      getAssetBeacon({ tenantId: rootState.User.tenant.tenantId, assetId: options.assetId, mac: options.mac, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: options.sortDesc, active: options.active }, message => {
        if (message.data.items.length > 0) {
          commit('SET_ASSET_BEACON', message.data.items[0])
          resolve(message.data.items[0])
        } else {
          commit('SET_ASSET_BEACON', {})
          resolve(message.data)
        }
      }, error => {
        reject(error)
      })
    })
  },
  GET_ASSET_UTILIZATION ({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      getAssetUtilization({ tenantId: rootState.User.tenant.tenantId, assetId: options.assetId, mac: options.mac, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: options.sortDesc, active: options.active }, message => {
        if (message.data.items.length > 0) {
          commit('SET_ASSET_UTILIZATION', message.data.items[0])
          resolve(message.data.items[0])
        } else {
          commit('SET_ASSET_UTILIZATION', {})
          resolve(message.data)
        }
      }, error => {
        reject(error)
      })
    })
  },
  GET_ASSET_STATUS ({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      getAssets({ tenantId: rootState.User.tenant.tenantId, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: (options.sortDesc) ? '1' : '0', keyword: '', brandId: '', modelId: '', supplierId: '', assetTypeId: '', riskLevel: '', statusId: '', active: 1, assetId: options.assetId, isPooling: '', isTracking: '' }, message => {
        if (message.data.items.length > 0) {
          resolve(message.data.items[0])
        } else {
          reject(message.data)
        }
      }, error => {
        reject(error)
      })
    })
  },
  GET_ASSET_ACTION ({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      getAssetBeacon({ tenantId: rootState.User.tenant.tenantId, assetId: options.assetId, mac: options.mac, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: options.sortDesc, active: options.active }, message => {
        resolve(message.data.items)
      }, error => {
        reject(error)
      })
    })
  },
  GET_ASSET_STOCK ({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      getAssetStock({ tenantId: rootState.User.tenant.tenantId, modelId: options.modelId, brand: options.brand, model: options.model, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: options.sortDesc, assetTypeNameTH: options.assetTypeNameTH, assetTypeNameEN: options.assetTypeNameEN, statusId: options.statusId }, message => {
        commit('SET_ASSET_STOCK', message.data.items)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  SET_ASSET_TO_AVAILABLE ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postPrepareAsset({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  UPDATE_ASSET ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      getAssets({ tenantId: rootState.User.tenant.tenantId, pageSize: 1, pageNo: 1, sortBy: '', sortDesc: '', keyword: '', brandId: '', modelId: '', supplierId: '', assetTypeId: '', riskLevel: '', statusId: '', active: '', assetId: data.assetId, isPooling: '', isTracking: '' }, message => {
        if (message.data.items.length > 0) {
          data.etag = message.data.items[0].etag
          patchAsset({ tenantId: rootState.User.tenant.tenantId }, data, message => {
            resolve(message)
          }, error => {
            if (error.response.status === 409) {
              reject(error.response.data)
            } else {
              reject(new Error('ล้มเหลว'))
            }
          })
        } else {
          reject(message.data)
        }
      }, error => {
        reject(error)
      })
    })
  },
  GET_ASSET_TYPE ({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      getAssetType({ tenantId: rootState.User.tenant.tenantId, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: options.sortDesc, assetTypeNameTH: options.assetTypeNameTH, assetTypeNameEN: options.assetTypeNameEN }, message => {
        if (message.data) {
          commit('SET_ASSET_TYPES', message.data.items)
          resolve(message)
        } else {
          reject(message)
        }
      }, error => {
        reject(error)
      })
    })
  },
  GET_STATUS ({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      getAssetStatus({ tenantId: rootState.User.tenant.tenantId }, message => {
        if (message.data) {
          commit('SET_STATUS', message.data.items)
          resolve(message)
        } else {
          reject(message)
        }
      }, error => {
        reject(error)
      })
    })
  },
  GET_BRANDS ({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      getBrands({ tenantId: rootState.User.tenant.tenantId }, message => {
        if (message.data) {
          commit('SET_BRANDS', message.data.items)
          resolve(message)
        } else {
          reject(message)
        }
      }, error => {
        reject(error)
      })
    })
  },
  GET_MODELS ({ commit, state, rootState }, options) {
    return new Promise((resolve, reject) => {
      if (state.models.length === 0) {
        getModels({ tenantId: rootState.User.tenant.tenantId, modelId: options.modelId, model: options.model, brand: options.brand, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: options.sortDesc, assetTypeNameTH: options.assetTypeNameTH, assetTypeNameEN: options.assetTypeNameEN }, message => {
          if (message.data) {
            commit('SET_MODELS', message.data.items)
            resolve(message)
          } else {
            reject(message)
          }
        }, error => {
          reject(error)
        })
      } else {
        resolve()
      }
    })
  },
  GET_SUPPLIERS ({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      getSuppliers({ tenantId: rootState.User.tenant.tenantId, supplierId: options.supplierId, supplierName: options.supplierName, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: options.sortDesc, assetTypeNameTH: options.assetTypeNameTH, assetTypeNameEN: options.assetTypeNameEN }, message => {
        if (message.data) {
          commit('SET_SUPPLIERS', message.data.items)
          resolve(message)
        } else {
          reject(message)
        }
      }, error => {
        reject(error)
      })
    })
  },
  GET_ASSET_LIST ({ commit, state, rootState, dispatch }) {
    const { sortBy, sortDesc } = state.options
    return new Promise((resolve, reject) => {
      getAssets({ tenantId: rootState.User.tenant.tenantId, pageSize: state.options.itemsPerPage, pageNo: state.options.page, sortBy: (sortBy[0]) ? sortBy[0] : '', sortDesc: (sortDesc[0]) ? sortDesc[0] : '', keyword: state.keyword, brandId: state.filter.brandId, modelId: state.filter.modelId, supplierId: state.filter.supplierId, assetTypeId: state.filter.assetTypeId, riskLevel: state.filter.riskLevel, statusId: state.filter.statusId, active: state.filter.active, assetId: state.filter.assetId, isPooling: state.filter.isPooling, isTracking: state.filter.isTracking }, message => {
        const macs = select(message.data, 'items', 'mac')
        dispatch('Assets/GET_BULK_POSTION', macs.items.filter(n => n), { root: true }).then((innerMessage) => {
          commit('SET_ASSET_LIST_WITH_LOCATION', { assets: message.data, location: innerMessage })
          // commit('SET_DISTRIBUTE_LIST', { distribute: message.data, location: innerMessage })
          resolve(message)
        }).catch((error) => {
          console.log(error)
          reject(error)
        })
        // commit('SET_ASSETS_LIST', message.data)
        // resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_ALL_ASSETS ({ commit, rootState }, { options, query }) {
    return new Promise((resolve, reject) => {
      getAssets({ tenantId: rootState.User.tenant.tenantId, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: (options.sortDesc) ? '1' : '0', keyword: query.keyword, brandId: query.filter.brandId, modelId: query.filter.modelId, supplierId: query.filter.supplierId, assetTypeId: query.filter.assetTypeId, riskLevel: query.filter.riskLevel, statusId: query.filter.statusId, assetId: query.filter.assetId, isPooling: query.filter.isPooling, isTracking: query.filter.isTracking }, message => {
        commit('SET_ASSETS_LIST', message.data)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_ALL_AVAILABLE_ASSET ({ commit, rootState }, { options, query }) {
    return new Promise((resolve, reject) => {
      getAssets({ tenantId: rootState.User.tenant.tenantId, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: (options.sortDesc) ? '1' : '0', keyword: query.keyword, brandId: query.filter.brandId, modelId: query.filter.modelId, supplierId: query.filter.supplierId, assetTypeId: query.filter.assetTypeId, riskLevel: query.filter.riskLevel, statusId: query.filter.statusId, assetId: query.filter.assetId, isPooling: query.filter.isPooling, isTracking: query.filter.isTracking }, message => {
        commit('SET_AVAILABLE_ASSET_LIST', message.data.items)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  ADD_UTILIZATION ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postAssetUtilization({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  ADD_BEACON ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postAssetBeacon({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  DELETE_ASSET_BEACON ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      deleteAssetBeacon({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  DELETE_ASSET_UTILIZATION ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      deleteAssetUtilization({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_ASSET_IMAGE ({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      getAssetImage({ tenantId: rootState.User.tenant.tenantId, modelImage: options.modelImage }, message => {
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  UPLOAD_ASSET_IMAGE ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postAssetImage({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  SET_SEARCH_ASSET_ID ({ commit }, data) {
    commit('SET_SEARCH_ASSET_ID', data)
  },
  SET_TABLE_OPTIONS ({ commit, dispatch }, options) {
    commit('SET_OPTIONS', options)
    return new Promise((resolve, reject) => {
      dispatch('Assets/GET_ASSET_LIST', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  SET_TABLE_FILTER ({ commit }, { element, value }) {
    commit('SET_FILTER', { element: element, value: value })
  },
  APPLY_FILTER ({ commit, state, dispatch }) {
    commit('SET_OPTIONS', state.defaultOptions)
    return new Promise((resolve, reject) => {
      dispatch('Assets/GET_ASSET_LIST', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  SET_TABLE_DEFAULT_OPTIONS ({ commit }, options) {
    commit('SET_DEFAULT_OPTIONS', options)
  },
  SET_KEYWORD ({ commit }, keyword) {
    commit('SET_KEYWORD', keyword)
  },
  RESET_FILTER ({ commit, state, dispatch }) {
    commit('SET_OPTIONS', state.defaultOptions)
    commit('SET_FILTER_TO_DEFAULT')
    return new Promise((resolve, reject) => {
      dispatch('Assets/GET_ASSET_LIST', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  RESET_FILTER_NOT_RELOAD ({ commit, state, dispatch }) {
    // commit('SET_OPTIONS', state.defaultOptions)
    commit('SET_FILTER_TO_DEFAULT')
    if (!(Object.keys(state.defaultOptions).length === 0)) {
      commit('SET_OPTIONS', state.defaultOptions)
    }
  },
  CREATE_ASSET ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postAsset({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  CREATE_MODEL ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postModels({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  CREATE_ASSET_TYPE ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postAssetType({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  CREATE_BRANDS ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postBrands({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  CREATE_SUPPILER ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postSuppliers({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
