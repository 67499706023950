
export default {
  primary: {
    base: '#245DDB',
    lighten5: '#DDE5F8',
    lighten4: '#BECEF3',
    lighten3: '#9FB7EE',
    lighten2: '#80A0E9',
    lighten1: '#4374DF',
    darken1: '#1E4FBA',
    darken2: '#19429B',
    darken3: '#14357D',
    darken4: '#0A1A3E'
  },
  secondary: {
    base: '#EC2878',
    lighten5: '#FBDFEA',
    lighten4: '#F8C0D7',
    lighten3: '#F6A1C4',
    lighten2: '#F383B0',
    lighten1: '#F1659D',
    darken1: '#CA2266',
    darken2: '#A81C55',
    darken3: '#861644',
    darken4: '#651133'
  },
  success: {
    base: '#27AE5F',
    lighten5: '#E6F5EB',
    lighten4: '#C2E6CD',
    lighten3: '#9AD6AE',
    lighten2: '#70C68E',
    lighten1: '#4FBA77',
    darken1: '#1F9F55',
    darken2: '#148D49',
    darken3: '#097C3E',
    darken4: '#005D2B '
  },
  error: {
    base: '#EC2831',
    lighten5: '#FFE9ED',
    lighten4: '#FFC9CE',
    lighten3: '#F09293',
    lighten2: '#E66769',
    lighten1: '#EC2831',
    darken1: '#E41725',
    darken2: '#D30120',
    darken3: '#C60018',
    darken4: '#B6000B'
  },
  warning: {
    base: '#ECAF00',
    lighten5: '#FDF6E0',
    lighten4: '#F9E6B0',
    lighten3: '#F5D67D',
    lighten2: '#F1C749',
    lighten1: '#EEBA22',
    darken1: '#ECA200',
    darken2: '#EB9000',
    darken3: '#EA7F00',
    darken4: '#AC4700'

  },
  info: {
    base: '#2E90FC',
    lighten5: '#E3F1FF',
    lighten4: '#BCDCFF',
    lighten3: '#91C7FF',
    lighten2: '#65B1FE',
    lighten1: '#45A0FE',
    darken1: '#2F81ED',
    darken2: '#2E6FD9',
    darken3: '#2C5EC7',
    darken4: '#283EA7'
  },
  natural: {
    base: '#f6f7f9',
    lighten5: '#FFFFFF',
    lighten4: '#FAFAFC',
    lighten3: '#F4F4F8',
    lighten2: '#F0F0F8',
    lighten1: '#DADCE8',
    darken1: '#505368',
    darken2: '#373A53',
    darken3: '#1E223E',
    darken4: '#050928'
  }
  // All keys will generate theme styles,
  // Here we add a custom `tertiary` color

}
