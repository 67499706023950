import { getLog } from '@/api/'

const state = () => ({
})

const mutations = {
}

const actions = {
  GET_LOG ({ commit, rootState }, { type }) {
    return new Promise((resolve, reject) => {
      getLog({ tenantId: rootState.User.tenant.tenantId, type: type }, message => {
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
