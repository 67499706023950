<template>
  <v-card
    class="natural fill-height pa-4 ma-4 mt-10"
    flat
  >
    <v-card-text
      class="text-center"
    >
      <v-avatar size="200" id="distribute-from-smartbadge" data-cy="distribute-from-smartbadge">
        <v-icon
          size="200"
          color="success"
        >
        {{iconCheckCircle}}
        </v-icon>
      </v-avatar>
      <div class="text-h1 text-center pa-4 font-weight-bold">
        ดำเนินการยืมสำเร็จ
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheckCircle } from '@mdi/js'
export default {
  data () {
    return {
      iconCheckCircle: mdiCheckCircle
    }
  }
}
</script>

<style>

</style>
