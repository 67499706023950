
import { getReport } from '@/api/'
import Vue from 'vue'

const state = () => ({
  reportList: [],
  report: [],
  reportDateTime: null,
  dailyActivityReport: [],
  dailyActivityReportHeader: [],
  cutoffReport: [],
  movementReportItems: [],
  movementReportHeaders: [],
  movementReportDatetime: null,
  movementByDepartmentReportItems: [],
  movementByDepartmentReportHeaders: [],
  movementByDepartmentReportDatetime: null,
  focusModelItems: [],
  focusModelHeaders: [],
  focusModelDatetime: null,
  focusModelSensorItems: [],
  focusModelSensorHeaders: [],
  focusModelSensorDatetime: null,
  reportHeaders: [],
  selectedReport: null
})
const mutations = {
  SET_SELECTED_REPORT (state, data) {
    state.selectedReport = data
  },
  SET_SELECTED_REPORT_FILTER (state, { element, value }) {
    state.selectedReport.filter[state.selectedReport.filter.indexOf(element)].value = value
  },
  SET_MOVEMENT_REPORT (state, data) {
    state.movementReportItems = data.items
    state.movementReportHeaders = data.headers
    state.movementReportDatetime = data.reportDate
  },
  SET_MOVEMENT_BY_DEPARTMENT_REPORT (state, data) {
    state.movementByDepartmentReportItems = data.items
    state.movementByDepartmentReportHeaders = data.headers
    state.movementByDepartmentReportDatetime = data.reportDate
  },
  SET_FOCUS_MODEL_REPORT (state, data) {
    state.focusModelItems = data.items
    state.focusModelHeaders = data.headers
    state.focusModelDatetime = data.reportDate
  },
  SET_FOCUS_MODEL_REPORT_BYSENSOR (state, data) {
    state.focusModelSensorItems = data.items
    state.focusModelSensorHeaders = data.headers
    state.focusModelSensorDatetime = data.reportDate
  },
  SET_REPORT (state, data) {
    state.report = data
  },
  SET_REPORT_DATETIME (state, data) {
    state.reportDateTime = data
  },
  SET_HEADER (state, data) {
    data.forEach((element) => {
      element.class = 'primary lighten-5'
      element.sortable = false
    })
    state.reportHeaders = data
  },
  SET_CUTOFF_REPORT (state, data) {
    state.cutoffReport = data
  },
  SET_DAILY_REPORT (state, data) {
    state.dailyActivityReport = data
  },
  SET_DAILY_REPORT_HEADER (state, data) {
    state.dailyActivityReportHeader = data
  },
  SET_REPORT_LIST (state, data) {
    state.reportList = data
  }
}
const actions = {
  GET_REPORT_LIST ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      getReport({ tenantId: rootState.User.tenant.tenantId, reportId: '', filter: '' }, message => {
        commit('SET_REPORT_LIST', message.data.items)
        resolve(message.data.items)
      }, error => {
        reject(error)
      })
    })
  },
  GET_REPORT ({ commit, rootState }, options) {
    let filter = ''
    if (options.filter) {
      options.filter.forEach(element => {
        if (element.type === 'month') {
          filter += `&${element.name}=${element.value}-01`
        } else {
          filter += `&${element.name}=${element.value}`
        }
      })
    }
    return new Promise((resolve, reject) => {
      getReport({ tenantId: rootState.User.tenant.tenantId, reportId: options.reportId, filter: filter }, message => {
        commit('SET_REPORT', message.data.items)
        commit('SET_REPORT_DATETIME', message.data.reportDate)
        commit('SET_HEADER', message.data.headers)
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  GET_CUTOFF_REPORT ({ commit, rootState }) {
    const options = {
      reportId: 2,
      reportDate: ''
    }
    return new Promise((resolve, reject) => {
      getReport({ tenantId: rootState.User.tenant.tenantId, reportId: options.reportId, filter: '' }, message => {
        commit('SET_CUTOFF_REPORT', message.data.items)
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  GET_MOVEMENT_REPORT ({ commit, rootState }) {
    const options = {
      reportId: 9, // 3
      reportDate: ''
    }
    return new Promise((resolve, reject) => {
      getReport({ tenantId: rootState.User.tenant.tenantId, reportId: options.reportId, filter: '' }, message => {
        commit('SET_MOVEMENT_REPORT', message.data)
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  GET_FOCUS_MODEL_REPORT ({ commit, rootState }) {
    const options = {
      reportId: 8,
      reportDate: ''
    }
    return new Promise((resolve, reject) => {
      getReport({ tenantId: rootState.User.tenant.tenantId, reportId: options.reportId, filter: '' }, message => {
        commit('SET_FOCUS_MODEL_REPORT', message.data)
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  GET_FOCUS_MODEL_REPORT_BYSENSOR ({ commit, rootState }) {
    const options = {
      reportId: 12,
      reportDate: ''
    }
    return new Promise((resolve, reject) => {
      getReport({ tenantId: rootState.User.tenant.tenantId, reportId: options.reportId, filter: '' }, message => {
        commit('SET_FOCUS_MODEL_REPORT_BYSENSOR', message.data)
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  GET_MOVEMENT_BY_DEPARTMENT_REPORT ({ commit, rootState }) {
    const options = {
      reportId: 7,
      reportDate: ''
    }
    return new Promise((resolve, reject) => {
      getReport({ tenantId: rootState.User.tenant.tenantId, reportId: options.reportId, filter: '' }, message => {
        commit('SET_MOVEMENT_BY_DEPARTMENT_REPORT', message.data)
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  GET_DAILY_REPORT ({ commit, rootState }) {
    const reportOptions = {
      reportId: '1',
      reportDate: Vue.prototype.$dayjs().locale('th').format('YYYY-MM-DD')
    }
    return new Promise((resolve, reject) => {
      getReport({ tenantId: rootState.User.tenant.tenantId, reportId: reportOptions.reportId, filter: `&reportDate=${reportOptions.reportDate}` }, message => {
        commit('SET_DAILY_REPORT', message.data.items[0])
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  RESET_REPORT ({ commit }) {
    commit('SET_REPORT', [])
    commit('SET_HEADER', [])
  }

}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
