export function getAssets (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/assets?keyword=${param.keyword}&brandId=${param.brandId}&modelId=${param.modelId}&supplierId=${param.supplierId}&assetTypeId=${param.assetTypeId}&riskLevel=${param.riskLevel}&statusId=${param.statusId}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}&pageSize=${param.pageSize}&pageNo=${param.pageNo}&assetId=${param.assetId}&isPooling=${param.isPooling}&isTracking=${param.isTracking}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postPrepareAsset (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/prepareasset`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getRooms (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-rooms?page=&size=&roomId=${param.roomId}&size=100`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getDeviceByLocation (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-positions?floorId=${param.floorId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getBulkPosition (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-positions`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getLogTelemetry (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-telemetry?trackingId=${param.trackingId}&startTime=${param.startTime}&endTime=${param.endTime}&page=&size=500&sort`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getLogPosition (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-positions-history?startIndex=0&maxResult=500&trackingId=${param.trackingId}&startTime=${param.startTime}&endTime=${param.endTime}&page=&size=500&sort`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getGateway (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-device-type?deviceType=${param.deviceType}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getDevice (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-device?startIndex=${param.startIndex}&maxResult=${param.maxResult}&tenantId=${param.tenantId.toLowerCase()}&deviceType=${param.deviceType}&queryType=COUNTED&q=${param.uniqueId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getUsers (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/users?tenantId=${param.tenantId.toLowerCase()}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getTempUsers (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/tempusers?tenantId=${param.tenantId.toLowerCase()}&pageSize=30&continuationToken=&email=`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postUsers (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/users`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteUsers (param, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: `${param.tenantId.toLowerCase()}/service/users?uuid=${param.uuid}&tenantId=${param.tenantId.toLowerCase()}&etag=${param.etag}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getRole (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/roles?continuationToken=&pageSize=`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getDepartments (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/departments?departmentId=${param.departmentId}&departmentName=${param.departmentName}&pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postDepartment (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/departments`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchDepartment (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: `${param.tenantId.toLowerCase()}/service/departments`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteDepartment (param, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: `${param.tenantId.toLowerCase()}/service/departments?tenantId=${param.tenantId.toLowerCase()}'&departmentId=${param.departmentId}&etag=${param.etag}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postAsset (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/assets`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchAsset (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: `${param.tenantId.toLowerCase()}/service/assets`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteAsset (param, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: `${param.tenantId.toLowerCase()}/service/assets?tenantId=${param.tenantId.toLowerCase()}&assetId=${param.assetId}&etag=${param.etag}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getBooking (param, cb, errorCb) {
  // bookingId=&brandId=&modelId=&assetTypeId=&trackingId=&pageSize=&pageNo=&sortBy=bookingId&sortDesc=1
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/booking?tenantId=${param.tenantId.toLowerCase()}&departmentId=${param.departmentId}&active=1&bookingId=${param.bookingId}&brandId=${param.brandId}&modelId=${param.modelId}&assetTypeId=${param.assetTypeId}&trackingId=${param.trackingId}&pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}&startDate=${param.startDate}&endDate=${param.endDate}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postBooking (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/booking`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchBooking (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: `${param.tenantId.toLowerCase()}/service/booking`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteBooking (param, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: `${param.tenantId.toLowerCase()}/service/booking?bookingId=${param.bookingId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getLocation (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/locations?pageSize=-1&pageNo=&sortBy=&sortDesc=&roomId=${param.roomId}&buildingId=${param.buildingId}&isCheckinPoint=${param.isCheckinPoint}&isCheckoutPoint=${param.isCheckoutPoint}&active=${param.active}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postLocation (param, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/locations`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchLocation (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: `${param.tenantId.toLowerCase()}/service/locations`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
} 

export function getPresence (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-presences?size=1000&tenantId=${param.tenantId.toLowerCase()}&trackingId=${param.trackingId}&page&size=&roomId=${param.roomId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getPresenceHistory (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-presences-history?size=1000&tenantId=${param.tenantId.toLowerCase()}&trackingId=${param.trackingId}&startTime=${param.startTime}&endTime=${param.endTime}&queryType=COUNTED&page=${param.page}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getPresenceHistoryPromise (param) {
  return window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-presences-history?size=1000&tenantId=${param.tenantId.toLowerCase()}&trackingId=${param.trackingId}&startTime=${param.startTime}&endTime=${param.endTime}&queryType=COUNTED&page=${param.page}`
  })
}

export function getPosition (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-positions?size=500&tenantId=${param.tenantId.toLowerCase()}&trackingId=${param.trackingId}&page&size=`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getPositionHistory (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-positions-history?size=${param.size}&tenantId=${param.tenantId.toLowerCase()}&trackingId=${param.trackingId}&startTime=${param.startTime}&page=${param.page}&queryType=COUNTED`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getTelemetry (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-telemetry-latest?size=500&tenantId=${param.tenantId.toLowerCase()}&sourceIds=${param.sourceIds}&startTime=${param.startTime}&page&size=`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getTelemetryHistory (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-telemetry?size=2000&tenantId=${param.tenantId.toLowerCase()}&trackingId=${param.trackingId}&startTime=${param.startTime}&endTime=${param.endTime}&page`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getRoomSensors (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/kontakt-roomsensors?size=500&page=${param.page}&size=${param.size}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getฺBookingFromBeacon (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/booking?pageSize=-1&pageNo=&sortBy=&sortDesc=&trackingId=${param.trackingId}&active=true`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getBadgeFromDept (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/departmentbeacon?departmentId=${param.departmentId}&departmentName=${param.departmentName}&pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}&active=${param.active}&mac`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postBadge (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/departmentbeacon`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteBadge (param, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: `${param.tenantId.toLowerCase()}/service/departmentbeacon?departmentBeaconId=${param.departmentBeaconId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getAssetType (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/assettype?pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}&assetTypeNameTH=${param.assetTypeNameTH}&assetTypeNameEN=${param.assetTypeNameEN}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postAssetType (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/assettype`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getBrands (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/brands`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}
export function postBrands (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/brands`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getAssetStatus (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/assetstatus`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getModels (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/models?modelId=${param.modelId}&model=${param.model}&brand=${param.brand}&pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}&assetTypeNameTH=${param.assetTypeNameTH}&assetTypeNameEN=${param.assetTypeNameEN}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postModels (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/models`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getSuppliers (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/suppliers?supplierId=${param.supplierId}&supplierName=${param.supplierName}&pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postSuppliers (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/suppliers`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getAssetBeacon (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/assetbeacon?mac=${param.mac}&assetId=${param.assetId}&pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}&active=${param.active}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postAssetBeacon (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/assetbeacon`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteAssetBeacon (param, data, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: `${param.tenantId.toLowerCase()}/service/assetbeacon`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getDistribute (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/borrowasset?pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}&isReturn=${param.isReturn}&assetId=${param.assetId}&keyword=${param.keyword}&distributionId=${param.distributionId}&bookingId=${param.bookingId}&brandId=${param.brandId}&modelId=${param.modelId}&borrowDepartmentId=${param.borrowDepartmentId}&isOverDowntime=${param.isOverDowntime}&startDate=${param.startDate}&endDate=${param.endDate}&returnStartDate=${param.returnStartDate}&returnEndDate=${param.returnEndDate}&assetTypeId=${param.assetTypeId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postDistribute (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/borrowasset`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postReturnAsset (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/returnasset`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getAssetStock (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/assetstock?&brand=${param.brand}&model=${param.model}&modelId=${param.modelId}&pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}&assetTypeNameEN=${param.assetTypeNameEN}&assetTypeNameTH=${param.assetTypeNameTH}&keyword=${param.assetTypeNameTH}&isPooling=1`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postBeacon (param, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/beacons`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getBeacon (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/beacons?mac=&uniqueId=&beaconType=&pageSize=-1&pageNo=&sortBy=mac&sortDesc=0`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getReport (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/reports?&reportId=${param.reportId}${param.filter}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getAssetImage (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/modelimage?&modelImage=${param.modelImage}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postAssetImage (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/modelimage`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getDepartmentLocation (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/departmentlocation?departmentId=${param.departmentId}&departmentName=${param.departmentName}&pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}&active=${param.active}&roomId=${param.roomId}&roomName=${param.roomName}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postDepartmentLocation (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/departmentlocation`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteDepartmentLocation (param, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: `${param.tenantId.toLowerCase()}/service/departmentlocation?departmentLocationId=${param.departmentLocationId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getTroubleshootingStatus (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/troubleshooting-status?pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}&troubleshootingStatusId=${param.troubleshootingStatusId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getTroubleshootingProblem (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/troubleshooting-problem?pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postTroubleshootingProblem (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/troubleshooting-problem`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchTroubleshootingProblem (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: `${param.tenantId.toLowerCase()}/service/troubleshooting-problem`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteTroubleshootingProblem (param, data, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: `${param.tenantId.toLowerCase()}/service/troubleshooting-problem`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getTroubleshooting (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/troubleshooting?pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}&troubleshootingOrderId=${param.troubleshootingOrderId}&assetId=${param.assetId}&departmentId=${param.departmentId}&status=${param.status}&startDate=${param.startDate}&endDate=${param.endDate}&keyword=${param.keyword}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postTroubleshooting (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/troubleshooting`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function patchTroubleshooting (param, data, cb, errorCb) {
  window.axios({
    method: 'patch',
    url: `${param.tenantId.toLowerCase()}/service/troubleshooting`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteTroubleshooting (param, data, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: `${param.tenantId.toLowerCase()}/service/troubleshooting`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getDepartmentEmail (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/departmentemail?pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}&departmentId=${param.departmentId}&departmentEmailId=${param.departmentEmailId}&email=${param.email}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postDepartmentEmail (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/departmentemail`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteDepartmentEmail (param, data, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: `${param.tenantId.toLowerCase()}/service/departmentemail`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getPermission (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/permissions?continuationToken=&pageSize=&roleId=${param.roleId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getAssetFromBeacon (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/assetbeacon-list`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}
// New Endpoint
export function getDepartmentFromBeacon (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/departmentbeacon-list`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getInternalGateway (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/iot/devices?deviceType=gateway&pageSize=&continuationToken&tenantId=${param.tenantId.toLowerCase()}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getInternalDevice (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/iot/devices?deviceType=beacon&pageSize=&continuationToken&tenantId=${param.tenantId.toLowerCase()}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getInternalRooms (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/iot/rooms`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postInternalPositions (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/iot/position-lastest`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getInternalPosition (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/iot/position-lastest?pageSize=&continuationToken&trackingId=${param.trackingId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getInternalPositionHistory (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/iot/positions?pageSize=&continuationToken&trackingId=${param.trackingId}&startTime=${param.startTime}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getInternalPresenceHistory (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/iot/presences?pageSize=&continuationToken&trackingId=${param.trackingId}&startTime=${param.startTime}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getInternalPresences (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/iot/presence-lastest?pageSize=&continuationToken&trackingId=${param.trackingId}&roomId=${param.roomId}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getInternalTelemetryLastest (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/iot/telemetry-lastest?pageSize=&continuationToken&trackingId=${param.trackingId}&startTime=`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getInternalTelemetry (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/iot/telemetry?pageSize=&trackingId=${param.trackingId}&startTime=${param.startTime}&continuationToken=${param.continuationToken}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getInternalTelemetryFromSource (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/iot/telemetry?pageSize=&sourceId=${param.trackingId}&startTime=${param.startTime}&continuationToken=${param.continuationToken}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getInternalTelemetryPromise (param) {
  return window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/iot/telemetry?pageSize=&trackingId=${param.trackingId}&startTime=${param.startTime}&continuationToken=${param.continuationToken}`
  })
}

export function getAssetUtilization (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/assetutilization?mac=${param.mac}&assetId=${param.assetId}&pageSize=${param.pageSize}&pageNo=${param.pageNo}&sortBy=${param.sortBy}&sortDesc=${param.sortDesc}&active=${param.active}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postAssetUtilization (param, data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: `${param.tenantId.toLowerCase()}/service/assetutilization`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function deleteAssetUtilization (param, data, cb, errorCb) {
  window.axios({
    method: 'delete',
    url: `${param.tenantId.toLowerCase()}/service/assetutilization`,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getTimePerLocation (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/timeperlocation?mac=${param.mac}&dayRange=${param.dayRange}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function searchAsset (param) {
  return window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/search-asset?keyword=${param.keyword}`
  })
}

export function getAnalytics (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/analytics?analyticReportId=${param.reportId}&startDate=${param.startDate}&endDate=${param.endDate}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getLog (param, cb, errorCb) {
  window.axios({
    method: 'get',
    url: `${param.tenantId.toLowerCase()}/service/log?type=${param.type}`
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}
