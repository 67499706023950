
import { getAssetBeacon, postDistribute, getฺBookingFromBeacon } from '@/api/'
import { notification } from '@/plugins/sound'
const state = () => ({
  activeOrder: null,
  orderLoading: false,
  currentBadge: '',
  assets: [],
  confirmQty: 0,
  complete: false,
  success: false,
  checkoutState: 'welcome'
})

const mutations = {
  SET_ACTIVE_ORDER (state, data) {
    state.activeOrder = data
    state.checkoutState = 'inprocess'
  },
  SET_ORDER_LOADING (state, data) {
    state.orderLoading = data
  },
  SET_CURRENT_BADGE (state, data) {
    state.currentBadge = data
  },
  SET_ASSETS (state, data) {
    if (state.assets.filter((element) => element.assetId === data.assetId).length > 0) {
      console.log('duplicate')
    } else {
      if (state.activeOrder) {
        for (let i = 0; i < state.assets.length; i++) {
          if (!state.assets[i].assetId && (state.assets[i].modelId === data.modelId)) {
            state.assets[i] = { ...state.assets[i], ...data }
            state.confirmQty = state.activeOrder.amount - state.assets.filter((element) => element.assetId).length
            if (state.confirmQty === 0) state.complete = true
            break
          }
        }
      } else {
        console.log('Please select active order')
      }
    }
  },
  SET_ASSETS_LIST (state, data) {
    state.assets = []
    state.confirmQty = data.amount
    state.complete = false
    state.success = false
    for (let i = 0; i < data.amount; i++) {
      state.assets.push(data)
    }
  },
  REMOVE_ASSET_ID (state, data) {
    const tag = state.assets.filter((element) => element.assetId === data.assetId)
    if (tag.length === 0) {
      console.log('not found')
    } else {
      for (let i = 0; i < state.assets.length; i++) {
        if (state.assets[i].assetId === data.assetId) {
          state.assets.splice(i, 1)
          state.assets.push(state.activeOrder)
          state.complete = false
        }
      }
    }
  },
  RESET_ASSET (state) {
    state.assets = []
  },
  RESET_ACTIVE_ORDER (state) {
    state.activeOrder = null
    state.checkoutState = 'welcome'
  },
  SET_SUCCESS (state, data) {
    state.success = data
    state.checkoutState = 'finish'
  }
}

const actions = {
  RESET_ASSET ({ commit }) {
    commit('RESET_ASSET')
  },
  RESET_ORDER ({ commit }) {
    commit('RESET_ACTIVE_ORDER')
  },
  CREATE_DISTRIBUTE_RECORD ({ commit, state, rootState }) {
    commit('SET_SUCCESS', true)
    return new Promise((resolve, reject) => {
      const assetId = []
      state.assets.forEach(element => {
        assetId.push(element.assetId)
      })
      const distributeRecord = { bookingId: state.activeOrder.bookingId, departmentId: state.activeOrder.departmentId, assetId: assetId, isSelfCheckout: 1 }
      postDistribute({ tenantId: rootState.User.tenant.tenantId }, distributeRecord, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  PROCESS_EVENT ({ commit, rootState }, data) {
    if (data.model) {
      switch (data.model) {
        case 'SMART_BADGE' :
          notification.play()
          // Need discussion for standard name
          if (data.isBlueClick) {
            commit('SET_ORDER_LOADING', true)
            commit('SET_CURRENT_BADGE', data.mac)
            getฺBookingFromBeacon({ tenantId: rootState.User.tenant.tenantId, departmentName: '', trackingId: data.mac }, message => {
              if (message.data.items.length > 0) {
                commit('SET_ACTIVE_ORDER', message.data.items[0])
                commit('SET_ASSETS_LIST', message.data.items[0])
              }
              commit('SET_ORDER_LOADING', false)
            }, error => {
              console.log(error)
              commit('SET_ORDER_LOADING', false)
            })
            // getDepartment({ tenantId: 'demo', departmentName: '', tagMac: data.event.properties.trackingId }, message => {
            //   if (message.data.value.length > 0) {
            //     getBooking({ tenantId: 'demo', status: 'reserve', departmentId: message.data.value[0].departmentId }, message => {
            //       if (message.data.value.length > 0) {
            //         commit('SET_ACTIVE_ORDER', message.data.value[0])
            //         commit('SET_ASSETS_LIST', message.data.value[0])
            //         commit('SET_ORDER_LOADING', false)
            //       } else {
            //         commit('SET_ORDER_LOADING', false)
            //       }
            //     }, error => {
            //       console.log(error)
            //       commit('SET_ORDER_LOADING', false)
            //     })
            //   }
            // }, error => {
            //   console.log(error)
            //   commit('SET_ORDER_LOADING', false)
            // })
          } else if (data.isRedClick) {
            console.log('red button press')
            commit('RESET_ACTIVE_ORDER')
            commit('RESET_ASSET')
          }
          break
        case 'ASSET_TAG_2' :
          if (data.isBlueClick) {
            console.log('ASSET_TAG_2')
            notification.play()
            commit('SET_ORDER_LOADING', true)
            const options = {
              itemsPerPage: -1,
              page: 1,
              sortBy: '',
              sortDesc: '',
              assetId: ''
            }
            getAssetBeacon({ tenantId: rootState.User.tenant.tenantId, assetId: options.assetId, mac: data.mac, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: options.sortDesc, active: '1' }, message => {
              if (message.data.items.length > 0) {
                const availableAsset = message.data.items.filter((element) => (element.statusId === 1 || element.statusId === 2 || element.statusId === 3))
                if (availableAsset.length > 0) {
                  commit('SET_ASSETS', availableAsset[0])
                }
              }
              commit('SET_ORDER_LOADING', false)
            }, error => {
              console.log(error)
              commit('SET_ORDER_LOADING', false)
            })
          } else if (data.isRedClick) {
            notification.play()
            const options = {
              itemsPerPage: -1,
              page: 1,
              sortBy: '',
              sortDesc: '',
              assetId: ''
            }
            getAssetBeacon({ tenantId: rootState.User.tenant.tenantId, assetId: options.assetId, mac: data.mac, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: options.sortDesc, active: '1' }, message => {
              if (message.data.items.length > 0) {
                commit('REMOVE_ASSET_ID', message.data.items[0])
              }
              commit('SET_ORDER_LOADING', false)
            }, error => {
              console.log(error)
              commit('SET_ORDER_LOADING', false)
            })
            console.log('red button press')
            // commit('REMOVE_ASSET_ID', data)
          }
          break
      }
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
