// import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import th from 'vuetify/lib/locale/th'
import light from './vuetify/theme'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    themes: {
      light: light
    },
    options: {
      customProperties: true,
      cspNonce: 'dQw4w9WgXcQ'
    }
  },
  lang: {
    locales: { th },
    current: 'th'
  }
})
