
import Vue from 'vue'
import { getDevice, getBeacon, getPresence, getPresenceHistory, getPosition, getPositionHistory, getTelemetry, getTelemetryHistory, postBeacon, getAssetFromBeacon, getDepartmentFromBeacon, getRooms, getInternalDevice, getInternalPosition, getInternalPositionHistory, getInternalPresenceHistory, getInternalPresences, getInternalTelemetry, getPresenceHistoryPromise } from '@/api/'
import * as alasql from 'alasql'

const state = () => ({
  beacons: [],
  beaconsKontakt: {},
  presence: {},
  presenceList: {},
  beacon: {},
  position: {},
  positionList: [],
  telemetry: {},
  telemetryList: [],
  options: {},
  uniqueId: ''
})

const mutations = {
  SET_BEACON_LIST_OPTION (state, data) {
    state.options = { ...state.options, ...data }
  },
  SET_BEACONS_LIST (state, data) {
    state.beacons = data
  },
  SET_PRESENCE (state, data) {
    state.presence = data
  },
  SET_CURRENT_BEACON (state, data) {
    state.beacon = data
  },
  SET_POSITION (state, data) {
    state.position = data
  },
  SET_TELEMETRY (state, data) {
    state.telemetry = data
  },
  SET_TELEMETRY_HISTORY (state, data) {
    state.telemetryList = data
  },
  SET_PRESENCE_HISTORY (state, data) {
    const filteredData = alasql('SELECT trackingId,roomName, floorName, buildingName, roomId ,DATE(startTime) as startTime,DATE(endTime) as endTime FROM ? WHERE DATEDIFF(minute,DATE(startTime),DATE(endTime)) > 1', [data])
    const groupData = []
    let current = filteredData[0]
    let currentLocation = []
    for (let i = 0; i < filteredData.length; i++) {
      if (filteredData[i].roomId === current.roomId) {
        currentLocation.push(filteredData[i])
        if (i === (filteredData.length - 1)) {
          current.startTime = currentLocation[0].startTime
          current.endTime = currentLocation[currentLocation.length - 1].endTime
          groupData.push(current)
        }
      } else {
        current.startTime = currentLocation[0].startTime
        current.endTime = currentLocation[currentLocation.length - 1].endTime
        groupData.push(current)
        currentLocation = [filteredData[i]]
        current = filteredData[i]
        if (i === (filteredData.length - 1)) {
          current.startTime = currentLocation[0].startTime
          current.endTime = currentLocation[currentLocation.length - 1].endTime
          groupData.push(current)
        }
      }
    }
    console.log(groupData)
    state.presenceList.items = groupData
    state.presenceList.total = groupData.length
  },
  SET_INTERNAL_PRESENCE_HISTORY (state, data) {
    state.presenceList.items = data.value
    state.presenceList.total = data.value.length
  },
  SET_POSITION_HISTORY (state, data) {
    state.positionList = data
  },
  SET_BEACONS_KONTAKT_LIST (state, data) {
    state.beaconsKontakt.items = data.devices
    state.beaconsKontakt.total = data.searchMeta.count
  },
  SET_BEACONS_INTERNAL_LIST (state, data) {
    state.beaconsKontakt.items = data
    state.beaconsKontakt.total = data.length
  },
  SET_UNIQUEID (state, data) {
    state.uniqueId = data
  }
}

const actions = {
  SET_CURRENT_BEACON ({ commit }, data) {
    commit('SET_CURRENT_BEACON', data)
  },
  GET_ALL_BEACONS ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      getBeacon({ tenantId: rootState.User.tenant.tenantId, deviceType: 'BEACON' }, message => {
        commit('SET_BEACONS_LIST', message.data.items)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  SET_OPTIONS ({ commit, dispatch }, options) {
    commit('SET_BEACON_LIST_OPTION', options)
    return new Promise((resolve, reject) => {
      dispatch('Beacons/GET_ALL_BEACONS_KONTAKT', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  GET_ALL_BEACONS_KONTAKT ({ commit, rootState, state }) {
    return new Promise((resolve, reject) => {
      const { itemsPerPage, page } = state.options
      const startIndex = (itemsPerPage * (page - 1))
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getDevice({ tenantId: rootState.User.tenant.tenantId, deviceType: 'BEACON', startIndex: startIndex, maxResult: (itemsPerPage === -1) ? 500 : itemsPerPage, uniqueId: state.uniqueId }, message => {
          commit('SET_BEACONS_KONTAKT_LIST', message.data)
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        getInternalDevice({ tenantId: rootState.User.tenant.tenantId }, message => {
          commit('SET_BEACONS_INTERNAL_LIST', message.data.value)
          resolve(message)
        }, error => {
          reject(error)
        })
      }
    })
  },
  GET_PRESENCE ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getPresence({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), roomId: param.roomId }, message => {
          commit('SET_PRESENCE', message.data.content)
          resolve(message.data.content)
        }, error => {
          reject(error)
        })
      } else {
        getInternalPresences({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), roomId: param.roomId }, message => {
          commit('SET_PRESENCE', message.data.value)
          resolve(message.data.value)
        }, error => {
          reject(error)
        })
      }
    })
  },
  GET_PRESENCE_HISTORY ({ commit, rootState }, param) {
    return new Promise(async (resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        try {
          let message = await getPresenceHistoryPromise({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: param.startTime, endTime: param.endTime, page: 0, size: param.itemsPerPage })
          let data = [...message.data.content]
          const totalPages = message.data.page.totalPages
          for (let i = 1; i < totalPages; i++) {
            message = await getPresenceHistoryPromise({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: param.startTime, endTime: param.endTime, page: i, size: param.itemsPerPage })
            data = [...data, ...message.data.content]
          }
          commit('SET_PRESENCE_HISTORY', data)
          resolve(data)
        } catch (error) {
          reject(error)
        }
        // getPresenceHistory({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: param.startTime, endTime: param.endTime, page: param.page, size: param.itemsPerPage }, message => {
        // getPresenceHistory({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: param.startTime, endTime: param.endTime, page: param.page, size: param.itemsPerPage }, message => {
        //   let data = [...message.data]
        //   const totalPages = message.data.page.totalPages
        //   for (let i = 1; i < totalPages; i++) {
        //     getPresenceHistory({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: param.startTime, endTime: param.endTime, page: i, size: param.itemsPerPage }, message => {
        //       data = [...data, ...message.data]
        //     }, error => {
        //       reject(error)
        //     })
        //   }
        //   // commit('SET_PRESENCE_HISTORY', message.data)
        //   resolve(message)
        // }, error => {
        //   reject(error)
        // })
      } else {
        getInternalPresenceHistory({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: Vue.prototype.$dayjs(param.startTime).format('YYYY-MM-DD'), endTime: param.endTime, page: param.page, size: param.itemsPerPage }, message => {
          commit('SET_INTERNAL_PRESENCE_HISTORY', message.data)
          resolve(message)
        }, error => {
          reject(error)
        })
      }
    })
  },
  GET_POSITION ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getPosition({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase() }, message => {
          commit('SET_POSITION', message.data.content)
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        getInternalPosition({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase() }, message => {
          commit('SET_POSITION', message.data.value)
          resolve(message)
        }, error => {
          reject(error)
        })
      }
    })
  },
  GET_POSITION_ROOM ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getPosition({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase() }, message => {
        // commit('SET_POSITION', message.data.content)
          if (message.data.content.length > 0) {
            let position = message.data.content[0]
            if (rootState.User.tenant.locationSource === 'kontakt') {
              getRooms({ tenantId: rootState.User.tenant.tenantId, roomId: message.data.content[0].roomId }, message => {
                if (message.data.content.length > 0) {
                  position = { ...position, ...message.data.content[0] }
                  console.log(position)
                  resolve(position)
                }
              })
            } else {
              console.log('call Moko')
            }
          }
        }, error => {
          reject(error)
        })
      } else {
        getInternalPosition({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase() }, message => {
          // let position = message.data.value[0]
          // const roomId = message.data.value[0].roomId
          message.data.value[0].name = message.data.value[0].roomName
          resolve(message.data.value[0])
          // getInternalRooms({ tenantId: rootState.User.tenant.tenantId }, message => {
          //   const room = message.data.value.filter(el => el.id === roomId)
          //   console.log(room)
          //   position = { ...position, ...room[0] }
          //   resolve(position)
          // })
        }, error => {
          reject(error)
        })
      }
    })
  },
  GET_POSITION_HISTORY ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getPositionHistory({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: param.startTime }, message => {
          commit('SET_POSITION_HISTORY', message.data.content)
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        console.log('call Moko')
        getInternalPositionHistory({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: Vue.prototype.$dayjs(param.startTime).format('YYYY-MM-DD') }, message => {
          commit('SET_POSITION_HISTORY', message.data.value)
          resolve(message)
        }, error => {
          reject(error)
        })
      }
    })
  },
  GET_TELEMETRY_FROM_SOURCE ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getTelemetry({ tenantId: rootState.User.tenant.tenantId, startTime: param.startTime, sourceIds: param.sourceIds }, message => {
          commit('SET_TELEMETRY', message.data.content)
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        console.log('call Moko')
      }
    })
  },
  GET_TELEMETRY_HISTORY ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getTelemetryHistory({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: param.startTime, endTime: param.endTime }, message => {
          commit('SET_TELEMETRY_HISTORY', message.data.content)
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        console.log('call Moko')
        getInternalTelemetry({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: Vue.prototype.$dayjs(param.startTime).format('YYYY-MM-DD'), endTime: param.endTime }, message => {
          commit('SET_TELEMETRY_HISTORY', message.data.value)
          resolve(message)
        }, error => {
          reject(error)
        })
      }
    })
  },
  UPDATE_BEACON_FROM_KONTAKT ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      postBeacon({ tenantId: rootState.User.tenant.tenantId }, message => {
        commit('SET_TELEMETRY_HISTORY', message.data)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_ASSET_FROM_BEACON ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      getAssetFromBeacon({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  GET_DEPARTMENT_FROM_BEACON ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      getDepartmentFromBeacon({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
