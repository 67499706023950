<template>
  <v-btn
    v-bind="$attrs"
    color="primary"
    v-on="inputListeners"
  >
<slot />
{{$attrs.title}}
  </v-btn>
</template>

<script>
export default {
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }
  }
}
</script>

<style>

</style>
