<template>
  <v-app-bar
    app
    elevate-on-scroll
    color="white"
    clipped-right
  >
  <v-app-bar-nav-icon @click.stop="$emit('drawerChange')"></v-app-bar-nav-icon>
    <v-toolbar-title class="ml-0">
      <v-img
        width="94"
        contain
        :src="require('@/assets/logo-text.png')"
      />
    </v-toolbar-title>
    <v-spacer />
    <Search v-if="!$vuetify.breakpoint.mobile && roles === 'center'"/>
    <v-spacer />
    <v-tooltip bottom v-if="!$vuetify.breakpoint.mobile && roles === 'center'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="secondary" outlined @click="start"
          v-bind="attrs"
          v-on="on"
        >
      <v-icon>{{iconCards}}</v-icon>
      ยืมเครื่องมือแพทย์อัตโนมัติ
    </v-btn>
      </template>
      <span>ยืมเครื่องมือแพทย์อัตโนมัติ</span>
    </v-tooltip>
    <v-divider
      class="pr-3 mr-3"
      vertical
    />
    <div class="text-body" v-if="!$vuetify.breakpoint.mobile">
    {{user.fullname}}<br><span class="text-caption">{{tenant.roleName}}</span>
    </div>
    <UserMenu @userProfile="profileDialog=true" />
     <v-dialog
      v-model="checkOutDialog"
      fullscreen
    >
    <SelfCheckOutCard @onClose="onClose()" :key="checkOutDialog"/>
    </v-dialog>
    <v-dialog v-model="bookingRequestDialog" max-width="800" v-if="!$vuetify.breakpoint.mobile" persistent>
      <BookingRequest @onClose="bookingRequestDialog = false"/>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { ringtone } from '@/plugins/ringtone'
import { EventBus } from '@/plugins/event-bus'
import { initSignalR } from '@/plugins/signalr'
import { mapState } from 'vuex'
import UserMenu from '@/components/mainlayout/UserMenu'
import Search from '@/components/common/MasterSearch'
import { mdiBell, mdiHelpCircleOutline, mdiCards } from '@mdi/js'
import SelfCheckOutCard from '@/components/checkout/SelfCheckOut'
import BookingRequest from '@/components/mainlayout/BookingRequest'

export default {
  components: {
    UserMenu,
    Search,
    SelfCheckOutCard,
    BookingRequest
  },
  data () {
    return {
      version: process.env.VUE_APP_VERSION,
      iconBell: mdiBell,
      iconHelp: mdiHelpCircleOutline,
      iconCards: mdiCards,
      checkOutDialog: false,
      online: false,
      internetPopup: false,
      bookingRequestDialog: false
    }
  },
  computed: {
    ...mapState('User', ['user', 'tenant', 'roles']),
    ...mapState('Notification', ['bookingRequest'])
  },
  mounted () {
    initSignalR()
    EventBus.$on('requestBooking', this.requestBooking)
    EventBus.$on('newMessage', this.selfCheckOut)
    if (this.bookingRequest.length > 0) {
      this.bookingRequestDialog = true
    }
  },
  async destroyed () {
    EventBus.$off('requestBooking', this.requestBooking)
    EventBus.$off('newMessage', this.selfCheckOut)
  },
  methods: {
    requestBooking (message) {
      if (!this.$vuetify.breakpoint.mobile) {
        this.bookingRequestDialog = true
        this.play()
      }
    },
    selfCheckOut () {
      this.checkOutDialog = true
    },
    async start () {
      this.checkOutDialog = true
    },
    onClose () {
      this.checkOutDialog = false
    },
    play () {
      ringtone.volume = 1
      ringtone.loop = true
      ringtone.play()
    }
  }
}
</script>

<style>

</style>
