
import { getUsers, postUsers, getTempUsers, getRole, deleteUsers } from '@/api/'

const state = () => ({
  users: [],
  roles: [],
  tempUsers: []
})

const mutations = {
  SET_USERS_LIST (state, data) {
    state.users = data
  },
  SET_TEMP_USERS_LIST (state, data) {
    state.tempUsers = data
  },
  SET_ROLE_LIST (state, data) {
    state.roles = data
  }
}

const actions = {
  GET_ALL_USERS ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      getUsers({ tenantId: rootState.User.tenant.tenantId }, message => {
        commit('SET_USERS_LIST', message.data.value)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_ALL_TEMP_USERS ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      getTempUsers({ tenantId: rootState.User.tenant.tenantId }, message => {
        commit('SET_TEMP_USERS_LIST', message.data.value)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  INVITE_USER ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postUsers({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_ALL_ROLE ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      getRole({ tenantId: rootState.User.tenant.tenantId }, message => {
        commit('SET_ROLE_LIST', message.data.value)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  DELETE_MEMBER ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      deleteUsers({ tenantId: rootState.User.tenant.tenantId, uuid: param.uuid, etag: param.etag }, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
