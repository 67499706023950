<template>
  <v-card class="natural mx-auto">
    <ProgressOverlay :value="orderLoading || loading" />
    <ToolBar
      title=""
      @onClose="onClose"
    />
    <v-scroll-y-transition>
      <WelcomeCard v-if="checkoutState === 'welcome' && !success" />
    </v-scroll-y-transition>
    <v-scroll-y-transition>
      <InprocessCard
        v-if="checkoutState === 'inprocess' && !success"
        :counter="5-patchCounter"
      />
    </v-scroll-y-transition>
    <v-scroll-y-transition>
      <SuccessCard v-if="success" />
    </v-scroll-y-transition>
  </v-card>
</template>

<script>
// import { initSignalR, stop } from '@/plugins/signalr'
import { EventBus } from '@/plugins/event-bus'
import { mapState } from 'vuex'
import ToolBar from '@/components/common/CenterToolBar'
import WelcomeCard from '@/components/checkout/WelcomeCard'
import ProgressOverlay from '@/components/common/ProgressOverlay'
import InprocessCard from '@/components/checkout/InprocessCard'
import SuccessCard from '@/components/checkout/SuccessCard'

export default {
  components: {
    // DeviceCard,
    ToolBar,
    WelcomeCard,
    ProgressOverlay,
    InprocessCard,
    SuccessCard
    // CountDownCard
  },
  data () {
    return {
      loading: false,
      messages: [],
      countDownDialog: false,
      success: false,
      patchCounter: 0,
      successCounter: 0
    }
  },
  computed: {
    ...mapState('CheckOut', ['orderLoading', 'activeOrder', 'assets', 'confirmQty', 'complete', 'checkoutState'])
  },
  watch: {
    complete (val) {
      if (val) this.startConfirm()
    }
  },
  async mounted () {
    this.loading = true
    EventBus.$on('onreconnecting', this.connecting)
    EventBus.$on('onreconnected', this.connected)
    this.$store.dispatch('CheckOut/RESET_ORDER')
    this.$store.dispatch('CheckOut/RESET_ASSET')
    this.loading = false
    // this.loading = true
    // await initSignalR()
    // this.loading = false
  },
  async destroyed () {
    EventBus.$off('onreconnecting', this.connecting)
    EventBus.$off('onreconnected', this.connected)
  },
  methods: {
    connecting () {
      this.loading = true
    },
    connected () {
      this.loading = false
    },
    resetOrder () {
      this.$store.dispatch('CheckOut/RESET_ORDER')
    },
    resetAsset () {
      this.$store.dispatch('CheckOut/RESET_ASSET')
    },
    startConfirm () {
      this.patchCounter = 0
      const timer = setInterval(() => {
        console.log(this.patchCounter++)
      }, 1000)
      setTimeout(() => {
        clearInterval(timer) // clear above interval after 5 seconds
        if (this.activeOrder.amount - this.assets.filter((element) => element.assetId).length === 0) {
          this.patchBooking()
        }
      }, 5000)
    },
    startSuccess () {
      this.success = true
      setTimeout(() => {
        this.$store.dispatch('CheckOut/RESET_ORDER')
        this.$store.dispatch('CheckOut/RESET_ASSET')
        this.success = false
      }, 3000)
    },
    patchBooking () {
      this.loading = true
      this.$store.dispatch('CheckOut/CREATE_DISTRIBUTE_RECORD').then(() => {
        this.loading = false
        this.startSuccess()
      }).catch(() => {
        this.loading = false
        this.startSuccess()
      })
    },
    async onClose () {
      await stop()
      this.$emit('onClose')
    }
  }
}
</script>

<style scoped>
.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>
