<template>
  <v-card
    :class="(assetId)? 'mx-auto': 'mx-auto pulse'"
    max-width="500"
  >
    <v-img
      height="300px"
      contain
      :src="assetImg || require('@/assets/polaroid.svg')"
    />
    <v-card-title class="font-weight-bold">
      {{ assetTypeNameTH }}
    </v-card-title>
    <v-card-subtitle>
      {{ assetTypeNameEN }} รุ่น {{ model }}
    </v-card-subtitle>
    <v-card-subtitle class="text-h4 font-weight-bold">
      {{ refId }}
    </v-card-subtitle>
    <v-card-text :class="(assetId)? 'success': ''" />
  </v-card>
</template>

<script>
export default {
  props: ['assetId', 'assetTypeNameTH', 'assetTypeNameEN', 'model', 'sn', 'modelImage', 'refId'],
  data () {
    return {
      toggle: false,
      assetImg: null
    }
  },
  mounted () {
    if (this.modelImage) {
      this.getImage(this.modelImage)
    }
  },
  methods: {
    getImage (value) {
      const queryOption = {
        modelImage: value
      }
      this.$store.dispatch('Assets/GET_ASSET_IMAGE', queryOption).then((data) => {
        this.assetImg = data.items.imageUrl
      }).catch(() => {
        this.assetImg = ''
      })
    },
  },
}
</script>

<style scoped>
.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>
 