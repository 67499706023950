
import { getTroubleshooting, getTroubleshootingStatus, getTroubleshootingProblem, postTroubleshooting, patchTroubleshooting } from '@/api/'

const state = () => ({
  troubleshootingList: {},
  options: {},
  defaultOptions: {},
  filter: {
    troubleshootingOrderId: '',
    assetId: '',
    departmentId: '',
    status: '',
    startDate: '',
    endDate: ''
  },
  status: [],
  problem: [],
  keyword: ''
})

const mutations = {
  SET_TROUBLESHOOTING_LIST (state, data) {
    state.troubleshootingList = data
  },
  SET_OPTIONS (state, data) {
    state.options = data
  },
  SET_DEFAULT_OPTIONS (state, data) {
    state.defaultOptions = data
  },
  SET_FILTER (state, { element, value }) {
    state.filter[element] = value
  },
  SET_STATUS (state, data) {
    state.status = data
  },
  SET_PROBLEM (state, data) {
    state.problem = data
  },
  SET_KEYWORD (state, data) {
    state.keyword = data
  },
  SET_FILTER_TO_DEFAULT (state) {
    state.filter = {
      troubleshootingOrderId: '',
      assetId: '',
      departmentId: '',
      status: '',
      startDate: '',
      endDate: ''
    }
    state.keyword = ''
  }
}

const actions = {
  GET_TROUBLESHOOTING_LIST ({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      getTroubleshooting({ tenantId: rootState.User.tenant.tenantId, pageSize: state.options.itemsPerPage, pageNo: state.options.page, sortBy: state.options.sortBy, sortDesc: (state.options.sortDesc) ? '1' : '0', troubleshootingOrderId: state.filter.troubleshootingOrderId, assetId: state.filter.assetId, departmentId: state.filter.departmentId, status: state.filter.status, startDate: state.filter.startDate, endDate: state.filter.endDate, keyword: state.keyword }, message => {
        commit('SET_TROUBLESHOOTING_LIST', message.data)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  SET_TABLE_OPTIONS ({ commit, dispatch }, options) {
    commit('SET_OPTIONS', options)
    return new Promise((resolve, reject) => {
      dispatch('Troubleshooting/GET_TROUBLESHOOTING_LIST', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  SET_TABLE_FILTER ({ commit }, { element, value }) {
    commit('SET_FILTER', { element: element, value: value })
  },
  APPLY_FILTER ({ commit, state, dispatch }) {
    commit('SET_OPTIONS', state.defaultOptions)
    return new Promise((resolve, reject) => {
      dispatch('Troubleshooting/GET_TROUBLESHOOTING_LIST', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  SET_TABLE_DEFAULT_OPTIONS ({ commit }, options) {
    commit('SET_DEFAULT_OPTIONS', options)
  },
  RESET_FILTER ({ commit, state, dispatch }) {
    commit('SET_OPTIONS', state.defaultOptions)
    commit('SET_FILTER_TO_DEFAULT')
    return new Promise((resolve, reject) => {
      dispatch('Troubleshooting/GET_TROUBLESHOOTING_LIST', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  GET_STATUS ({ commit, rootState }) {
    const options = {
      itemsPerPage: -1,
      page: 1,
      sortBy: '',
      sortDesc: ''
    }
    return new Promise((resolve, reject) => {
      getTroubleshootingStatus({ tenantId: rootState.User.tenant.tenantId, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: (options.sortDesc) ? '1' : '0', troubleshootingStatusId: '' }, message => {
        commit('SET_STATUS', message.data.items)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_PROBLEM ({ commit, rootState }) {
    const options = {
      itemsPerPage: -1,
      page: 1,
      sortBy: '',
      sortDesc: ''
    }
    return new Promise((resolve, reject) => {
      getTroubleshootingProblem({ tenantId: rootState.User.tenant.tenantId, pageSize: options.itemsPerPage, pageNo: options.page, sortBy: options.sortBy, sortDesc: (options.sortDesc) ? '1' : '0' }, message => {
        commit('SET_PROBLEM', message.data.items)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  CREATE_TROUBLESHOOTING ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postTroubleshooting({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  UPDATE_TROUBLESHOOTING ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      patchTroubleshooting({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  SET_KEYWORD ({ commit }, keyword) {
    commit('SET_KEYWORD', keyword)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
