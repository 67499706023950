
import Vue from 'vue'
import { getDevice, getPresence, getPresenceHistory, getPosition, getPositionHistory, getTelemetry, getTelemetryHistory, getRoomSensors, getRooms, getInternalRooms } from '@/api/'

const state = () => ({
  beacons: [],
  presence: {},
  presenceList: [],
  beacon: {},
  position: {},
  positionList: [],
  telemetry: {},
  telemetryList: [],
  temperatureSeries: [],
  humiditySeries: [],
  lightIntensitySeries: [],
  airQualitySeries: [],
  numberOfPeopleDetectedSeries: [],
  environmentSensor: [],
  roomSensors: []

})

const mutations = {
  SET_ROOM_SENSORS (state, data) {
    state.roomSensors = data
  },
  CLEAR_SENSOR_DATA (state) {
    state.environmentSensor = []
  },
  SET_SENSOR_DATA (state, { trackingId, data, roomId }) {
    state.telemetry = data
    const temperature = []
    const humiditySeries = []
    const lightIntensitySeries = []
    const airQualitySeries = []
    const numberOfPeopleDetectedSeries = []
    for (let i = 0; i < data.length; i++) {
      const time = Vue.prototype.$dayjs(data[i].timestamp).add(7, 'hour')
      if (data[i].temperature) temperature.push({ x: time, y: data[i].temperature.toFixed(0) })
      if (data[i].humidity) humiditySeries.push({ x: time, y: data[i].humidity.toFixed(0) })
      if (data[i].lightIntensity) lightIntensitySeries.push({ x: time, y: data[i].lightIntensity })
      if (data[i].airQuality) airQualitySeries.push({ x: time, y: data[i].airQuality })
      if (data[i].numberOfPeopleDetected) numberOfPeopleDetectedSeries.push({ x: time, y: data[i].numberOfPeopleDetected })
    }
    state.temperatureSeries = [{
      data: temperature
    }]
    state.humiditySeries = [{
      data: humiditySeries
    }]
    state.lightIntensitySeries = [{
      data: lightIntensitySeries
    }]
    state.airQualitySeries = [{
      data: airQualitySeries
    }]
    state.numberOfPeopleDetectedSeries = [{
      data: numberOfPeopleDetectedSeries
    }]
    const index = data.length - 1
    const room = {
      trackingId: trackingId,
      roomId: roomId,
      temperature: (data.length !== 0) ? data[index].temperature.toFixed(0) : 0,
      humidity: (data.length !== 0) ? data[index].humidity.toFixed(0) : 0,
      lightIntensity: (data.length !== 0) ? data[index].lightIntensity : 0,
      airQuality: (data.length !== 0) ? data[index].airQuality : 0,
      numberOfPeopleDetected: (data.length !== 0) ? data[index].numberOfPeopleDetected : 0,
      lastedUpdate: (data.length !== 0) ? Vue.prototype.$dayjs(data[index].timestamp) : '',
      temperatureSeries: [{
        data: temperature
      }],
      humiditySeries: [{
        data: humiditySeries
      }],
      lightIntensitySeries: [{
        data: lightIntensitySeries
      }],
      airQualitySeries: [{
        data: airQualitySeries
      }],
      numberOfPeopleDetectedSeries: [{
        data: numberOfPeopleDetectedSeries
      }]
    }
    // Check Insert Or Update room
    if (state.environmentSensor.filter((element) => element.trackingId === trackingId).length === 0) {
      state.environmentSensor.push(room)
    } else {
      state.environmentSensor.splice(state.environmentSensor.indexOf(state.environmentSensor.filter((element) => element.trackingId === trackingId)[0]), 1)
      state.environmentSensor.push(room)
    }
  },
  SET_BEACONS_LIST (state, data) {
    state.beacons = data
  },
  SET_PRESENCE (state, data) {
    state.presence = data
  },
  SET_CURRENT_BEACON (state, data) {
    state.beacon = data
  },
  SET_POSITION (state, data) {
    state.position = data
  },
  SET_TELEMETRY (state, data) {
    state.telemetry = data
  },
  SET_TELEMETRY_HISTORY (state, data) {
    state.telemetryList = data
  },
  SET_PRESENCE_HISTORY (state, data) {
    state.presenceList = data
  },
  SET_POSITION_HISTORY (state, data) {
    state.positionList = data
  }
}

const actions = {
  SET_CURRENT_BEACON ({ commit }, data) {
    commit('SET_CURRENT_BEACON', data)
  },
  GET_ALL_BEACONS ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getDevice({ tenantId: rootState.User.tenant.tenantId, deviceType: 'BEACON' }, message => {
          commit('SET_BEACONS_LIST', message.data.devices)
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        console.log('call Moko')
      }
    })
  },
  GET_PRESENCE ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getPresence({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase() }, message => {
          commit('SET_PRESENCE', message.data.content)
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        console.log('call Moko')
      }
    })
  },
  GET_PRESENCE_HISTORY ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getPresenceHistory({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: param.startTime }, message => {
          commit('SET_PRESENCE_HISTORY', message.data.content)
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        console.log('call Moko')
      }
    })
  },
  GET_POSITION ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      getPosition({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase() }, message => {
        commit('SET_POSITION', message.data.content)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_POSITION_HISTORY ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      getPositionHistory({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: param.startTime }, message => {
        commit('SET_POSITION_HISTORY', message.data.content)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_TELEMETRY_FROM_SOURCE ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      getTelemetry({ tenantId: rootState.User.tenant.tenantId, startTime: param.startTime, sourceIds: param.sourceIds }, message => {
        commit('SET_TELEMETRY', message.data.content)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_TELEMETRY_HISTORY ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      getTelemetryHistory({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: param.startTime, endTime: param.endTime }, message => {
        commit('SET_TELEMETRY_HISTORY', message.data.content)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_TELEMETRY_FROM_TRACKING ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      getTelemetryHistory({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: param.startTime, endTime: param.endTime }, message => {
        resolve(message.data.content)
      }, error => {
        reject(error)
      })
    })
  },
  GET_SENSOR_DATA ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      // console.log('GET_SENSOR_DATA')
      getTelemetryHistory({ tenantId: rootState.User.tenant.tenantId, trackingId: param.trackingId.toLowerCase(), startTime: param.startTime, endTime: param.endTime }, message => {
        commit('SET_SENSOR_DATA', { trackingId: param.trackingId.toLowerCase(), data: message.data.content, roomId: param.roomId })
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_ROOM_SENSORS ({ commit, rootState }) {
    commit('CLEAR_SENSOR_DATA')
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getRoomSensors({ tenantId: rootState.User.tenant.tenantId, page: '', size: '' }, message => {
          if (message.data.content) {
            commit('SET_ROOM_SENSORS', message.data.content)
          } else {
            commit('SET_ROOM_SENSORS', [])
          }
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        console.log('Call Moko')
        resolve([])
      }
    })
  },
  GET_ROOM_INFO ({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getRooms({ tenantId: rootState.User.tenant.tenantId, roomId: options.roomId }, message => {
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        getInternalRooms({ tenantId: rootState.User.tenant.tenantId }, message => {
          // options.roomId
          // commit('SET_ALL_ROOMS', message.data.value.filter(el => el.id === options.roomId))
          resolve(message.data.value.filter(el => el.id === options.roomId))
        }, error => {
          reject(error)
        })
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
