
import { getDistribute, postDistribute, postReturnAsset, getRooms, getBulkPosition, postInternalPositions, getInternalRooms } from '@/api/'

function select (data, key, item) {
  const selectedData = {}
  selectedData[key] = data[key].map(function (d) { return d[item] })
  return selectedData
}

const state = () => ({
  distributes: {},
  distributedByAssetId: {},
  options: {},
  optionsByAssetID: {},
  defaultOptionsByAssetID: {},
  defaultOptions: {},
  defaultFilter: {
    distributionId: '',
    bookingId: '',
    brandId: '',
    assetId: '',
    modelId: '',
    borrowDepartmentId: '',
    isReturn: '',
    isOverDowntime: '',
    startDate: '',
    endDate: '',
    returnStartDate: '',
    returnEndDate: '',
    assetTypeId: ''
  },
  filter: {
    distributionId: '',
    bookingId: '',
    brandId: '',
    assetId: '',
    modelId: '',
    borrowDepartmentId: '',
    isReturn: '',
    isOverDowntime: '',
    startDate: '',
    endDate: '',
    returnStartDate: '',
    returnEndDate: '',
    assetTypeId: ''
  },
  filterByAssetId: {
    distributionId: '',
    bookingId: '',
    brandId: '',
    assetId: '',
    modelId: '',
    borrowDepartmentId: '',
    isReturn: '',
    isOverDowntime: '',
    startDate: '',
    endDate: '',
    returnStartDate: '',
    returnEndDate: '',
    assetTypeId: ''
  },
  keyword: '',
  rooms: [],
  positions: []
})

const mutations = {
  SET_DISTRIBUTE_LIST (state, { distribute, location }) {
    const rooms = []
    location.forEach((element) => {
      state.rooms.forEach((subEl) => {
        if (subEl.id === element.roomId) {
          element = { ...element, ...subEl }
        }
      })
      delete element.id
      rooms.push(element)
    })
    state.positions = rooms
    for (let i = 0; i < distribute.items.length; i++) {
      rooms.forEach((element) => {
        if (element.trackingId === distribute.items[i].mac) {
          distribute.items[i] = { ...distribute.items[i], ...element }
        }
      })
    }
    state.distributes = distribute
  },
  SET_DISTRIBUTE_LIST_BY_ASSETID (state, data) {
    state.distributedByAssetId = data
  },
  SET_OPTIONS (state, data) {
    state.options = data
  },
  SET_OPTIONS_BY_ASSETID (state, data) {
    state.optionsByAssetID = data
  },
  SET_DEFAULT_OPTIONS (state, data) {
    state.defaultOptions = data
  },
  SET_DEFAULT_OPTIONS_BY_ASSETID (state, data) {
    state.optionsByAssetID = data
  },
  SET_FILTER (state, { element, value }) {
    state.filter[element] = value
  },
  SET_FILTER_BY_ASSETID (state, { element, value }) {
    state.filterByAssetId[element] = value
  },
  SET_FILTER_TO_DEFAULT (state) {
    state.keyword = ''
    state.filter = {
      distributionId: '',
      bookingId: '',
      brandId: '',
      assetId: '',
      modelId: '',
      borrowDepartmentId: '',
      isReturn: '',
      isOverDowntime: '',
      startDate: '',
      endDate: '',
      returnStartDate: '',
      returnEndDate: '',
      assetTypeId: ''
    }
  },
  SET_KEYWORD (state, data) {
    state.keyword = data
  },
  SET_ALL_ROOMS (state, data) {
    state.rooms = data
  },
  SET_POSITION (state, data) {
    const rooms = []
    data.forEach((element) => {
      state.rooms.forEach((subEl) => {
        if (subEl.id === element.roomId) {
          element = { ...element, ...subEl }
        }
      })
      rooms.push(element)
    })

    state.positions = rooms
    for (let i = 0; i < state.distributes.items.length; i++) {
      rooms.forEach((element) => {
        if (element.trackingId === state.distributes.items[i].mac) {
          state.distributes.items[i] = { ...state.distributes.items[i], ...element }
        }
      })
    }
  }
}

const actions = {
  GET_BULK_POSTION ({ commit, state, rootState }, data) {
    const body = {
      trackingId: data
    }
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getBulkPosition({ tenantId: rootState.User.tenant.tenantId }, body, message => {
          resolve(message.data)
        }, error => {
          reject(error)
        })
      } else {
        postInternalPositions({ tenantId: rootState.User.tenant.tenantId }, body, message => {
          resolve(message.data.value)
        }, error => {
          reject(error)
        })
      }
    })
  },
  GET_ALL_ROOM ({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        if (state.rooms.length === 0) {
          getRooms({ tenantId: rootState.User.tenant.tenantId, roomId: '' }, message => {
            commit('SET_ALL_ROOMS', message.data.content)
            resolve(message.data.content)
          }, error => {
            reject(error)
          })
        } else {
          resolve()
        }
      } else {
        getInternalRooms({ tenantId: rootState.User.tenant.tenantId }, message => {
          commit('SET_ALL_ROOMS', message.data.value)
          resolve(message.data.value)
        }, error => {
          reject(error)
        })
      }
    })
  },
  GET_DISTRIBUTED_BY_ASSETID ({ commit, state, rootState, dispatch }) {
    return new Promise((resolve, reject) => {
      getDistribute({ tenantId: rootState.User.tenant.tenantId, pageSize: state.optionsByAssetID.itemsPerPage, pageNo: state.optionsByAssetID.page, sortBy: (state.optionsByAssetID.sortBy) ? state.optionsByAssetID.sortBy : '', sortDesc: (state.optionsByAssetID.sortDesc) ? '1' : '0', isReturn: state.filterByAssetId.isReturn, assetId: state.filterByAssetId.assetId, keyword: '', distributionId: state.filterByAssetId.distributionId, bookingId: state.filterByAssetId.bookingId, brandId: state.filterByAssetId.brandId, modelId: state.filterByAssetId.modelId, borrowDepartmentId: state.filterByAssetId.borrowDepartmentId, isOverDowntime: state.filterByAssetId.isOverDowntime, startDate: state.filterByAssetId.startDate, endDate: state.filterByAssetId.endDate, returnStartDate: state.filterByAssetId.returnStartDate, returnEndDate: state.filterByAssetId.returnEndDate, assetTypeId: state.filterByAssetId.assetTypeId }, message => {
        commit('SET_DISTRIBUTE_LIST_BY_ASSETID', message.data)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_ALL_DISTRIBUTE ({ commit, state, rootState, dispatch }) {
    return new Promise((resolve, reject) => {
      getDistribute({ tenantId: rootState.User.tenant.tenantId, pageSize: state.options.itemsPerPage, pageNo: state.options.page, sortBy: (state.options.sortBy) ? state.options.sortBy : '', sortDesc: (state.options.sortDesc) ? '1' : '0', isReturn: state.filter.isReturn, assetId: state.filter.assetId, keyword: state.keyword, distributionId: state.filter.distributionId, bookingId: state.filter.bookingId, brandId: state.filter.brandId, modelId: state.filter.modelId, borrowDepartmentId: state.filter.borrowDepartmentId, isOverDowntime: state.filter.isOverDowntime, startDate: state.filter.startDate, endDate: state.filter.endDate, returnStartDate: state.filter.returnStartDate, returnEndDate: state.filter.returnEndDate, assetTypeId: state.filter.assetTypeId }, message => {
        const macs = select(message.data, 'items', 'mac')
        dispatch('Distribute/GET_BULK_POSTION', macs.items.filter(n => n), { root: true }).then((innerMessage) => {
          commit('SET_DISTRIBUTE_LIST', { distribute: message.data, location: innerMessage })
          resolve(message)
        }).catch((error) => {
          resolve()
          console.log(error)
        })
      }, error => {
        reject(error)
      })
    })
  },
  SET_TABLE_OPTIONS_BY_ASSETID ({ commit, dispatch }, options) {
    commit('SET_OPTIONS_BY_ASSETID', options)
    return new Promise((resolve, reject) => {
      dispatch('Distribute/GET_DISTRIBUTED_BY_ASSETID', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  SET_TABLE_OPTIONS ({ commit, dispatch }, options) {
    commit('SET_OPTIONS', options)
    return new Promise((resolve, reject) => {
      dispatch('Distribute/GET_ALL_DISTRIBUTE', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  SET_TABLE_FILTER_BY_ASSETID ({ commit }, { element, value }) {
    commit('SET_FILTER_BY_ASSETID', { element: element, value: value })
  },
  SET_TABLE_FILTER ({ commit }, { element, value }) {
    commit('SET_FILTER', { element: element, value: value })
  },
  APPLY_FILTER ({ commit, state, dispatch }) {
    commit('SET_OPTIONS', state.defaultOptions)
    return new Promise((resolve, reject) => {
      dispatch('Distribute/GET_ALL_DISTRIBUTE', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  RESET_FILTER ({ commit, state, dispatch }) {
    commit('SET_OPTIONS', state.defaultOptions)
    commit('SET_FILTER_TO_DEFAULT')
    return new Promise((resolve, reject) => {
      dispatch('Distribute/GET_ALL_DISTRIBUTE', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  SET_TABLE_DEFAULT_OPTIONS ({ commit }, options) {
    commit('SET_DEFAULT_OPTIONS', options)
  },
  CREATE_DISTRIBUTE_RECORD ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      data.isSelfCheckout = 0
      postDistribute({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  RETURN_ASSET ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postReturnAsset({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  RESET_FILTER_NOT_RELOAD ({ commit, state, dispatch }) {
    commit('SET_FILTER_TO_DEFAULT')
    if (!(Object.keys(state.defaultOptions).length === 0)) {
      commit('SET_OPTIONS', state.defaultOptions)
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
