<template>
  <v-toolbar class="text-h5">
    <v-toolbar-title class="ml-4">
      <v-img
        width="94"
        contain
        :src="require('@/assets/logo.svg')"
      />
    </v-toolbar-title>
    <v-spacer />
    {{ title }}
    <v-spacer />
    <v-btn
      icon
      @click="$emit('onClose')"
    >
      <v-icon>
        {{iconClose}}
      </v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { mdiClose } from '@mdi/js'
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      iconClose: mdiClose
    }
  },
}
</script>

<style>

</style>
