import { getAnalytics } from '@/api/'
import Vue from 'vue'

const state = () => ({
  utilizatation: [],
  minimumStock: [],
  overallUsage: [],
  usagePerTime: [],
  returnPerTime: []
})

const mutations = {
  SET_UTILIZATION (state, data) {
    state.utilizatation = data
  },
  SET_MINIMUM_STOCK (state, data) {
    state.minimumStock = data
  },
  SET_OVERALL_USAGE (state, data) {
    state.overallUsage = data
  },
  SET_USAGE_PER_TIME (state, data) {
    state.usagePerTime = data
  },
  SET_RETURN_PER_TIME (state, data) {
    state.returnPerTime = data
  }
}

const actions = {
  GET_UTILIZATION ({ commit, rootState }, { startDate, endDate }) {
    const reportOptions = {
      reportId: '5',
      startDate: Vue.prototype.$dayjs(startDate).format('YYYY-MM-DD'),
      endDate: Vue.prototype.$dayjs(endDate).format('YYYY-MM-DD')
    }
    return new Promise((resolve, reject) => {
      getAnalytics({ tenantId: rootState.User.tenant.tenantId, reportId: reportOptions.reportId, startDate: reportOptions.startDate, endDate: reportOptions.endDate }, message => {
        commit('SET_UTILIZATION', message.data.items)
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  GET_OVERALL_USAGE ({ commit, rootState }, { startDate, endDate }) {
    const reportOptions = {
      reportId: '4',
      startDate: Vue.prototype.$dayjs(startDate).format('YYYY-MM-DD'),
      endDate: Vue.prototype.$dayjs(endDate).format('YYYY-MM-DD')
    }
    return new Promise((resolve, reject) => {
      getAnalytics({ tenantId: rootState.User.tenant.tenantId, reportId: reportOptions.reportId, startDate: reportOptions.startDate, endDate: reportOptions.endDate }, message => {
        commit('SET_OVERALL_USAGE', message.data.items)
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  GET_MINIMUM_STOCK ({ commit, rootState }, { startDate, endDate }) {
    const reportOptions = {
      reportId: '3',
      startDate: Vue.prototype.$dayjs(startDate).format('YYYY-MM-DD'),
      endDate: Vue.prototype.$dayjs(endDate).format('YYYY-MM-DD')
    }
    return new Promise((resolve, reject) => {
      getAnalytics({ tenantId: rootState.User.tenant.tenantId, reportId: reportOptions.reportId, startDate: reportOptions.startDate, endDate: reportOptions.endDate }, message => {
        commit('SET_MINIMUM_STOCK', message.data.items)
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  GET_USAGE_PER_TIME ({ commit, rootState }, { startDate, endDate }) {
    const reportOptions = {
      reportId: '1',
      startDate: Vue.prototype.$dayjs(startDate).format('YYYY-MM-DD'),
      endDate: Vue.prototype.$dayjs(endDate).format('YYYY-MM-DD')
    }
    return new Promise((resolve, reject) => {
      getAnalytics({ tenantId: rootState.User.tenant.tenantId, reportId: reportOptions.reportId, startDate: reportOptions.startDate, endDate: reportOptions.endDate }, message => {
        commit('SET_USAGE_PER_TIME', message.data.items)
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  GET_RETURN_PER_TIME ({ commit, rootState }, { startDate, endDate }) {
    const reportOptions = {
      reportId: '2',
      startDate: Vue.prototype.$dayjs(startDate).format('YYYY-MM-DD'),
      endDate: Vue.prototype.$dayjs(endDate).format('YYYY-MM-DD')
    }
    return new Promise((resolve, reject) => {
      getAnalytics({ tenantId: rootState.User.tenant.tenantId, reportId: reportOptions.reportId, startDate: reportOptions.startDate, endDate: reportOptions.endDate }, message => {
        commit('SET_RETURN_PER_TIME', message.data.items)
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
