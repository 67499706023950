import Vue from 'vue'
import { getInternalTelemetryLastest, getInternalTelemetry, getInternalTelemetryPromise } from '@/api/'

const state = () => ({

})

const mutations = {

}

const actions = {
  GET_INTERNAL_TELEMETRY_LASTEST ({ commit, rootState }, trackingId) {
    return new Promise((resolve, reject) => {
      getInternalTelemetryLastest({ tenantId: rootState.User.tenant.tenantId, trackingId: trackingId }, message => {
        resolve(message.data.value[0])
      }, error => {
        reject(error)
      })
    })
  },
  GET_INTERNAL_TELEMETRY ({ commit, rootState }, { trackingId, startTime }) {
    let continuationToken = ''
    return new Promise(async (resolve, reject) => {
      try {
        const message = await getInternalTelemetryPromise({ tenantId: rootState.User.tenant.tenantId, trackingId: trackingId, startTime: startTime, continuationToken: continuationToken })
        if (message.data.continuationToken === '') {
          const data = message.data.value
          data.forEach(element => {
            element.timestamp = Vue.prototype.$dayjs(element.packetTimestamp).format('MM/DD/YYYY HH:mm')
            // element.time = Vue.prototype.$dayjs(element.packetTimestamp).format('HH:mm:ss A')
          })
          const reduceData = data.filter((v, i, a) => a.findIndex(v2 => (v2.timestamp === v.timestamp)) === i)
          console.log(reduceData)
          resolve(reduceData)
        } else {
          let data = message.data.value
          continuationToken = message.data.continuationToken
          while (continuationToken) {
            const loopMessage = await getInternalTelemetryPromise({ tenantId: rootState.User.tenant.tenantId, trackingId: trackingId, startTime: startTime, continuationToken: continuationToken })
            continuationToken = loopMessage.data.continuationToken
            data = [...data, ...loopMessage.data.value]
          }
          data.forEach(element => {
            element.timestamp = Vue.prototype.$dayjs(element.packetTimestamp).format('MM/DD/YYYY HH:mm')
            // element.time = Vue.prototype.$dayjs(element.packetTimestamp).format('HH:mm:ss A')
          })
          // console.log(data)
          const reduceData = data.filter((v, i, a) => a.findIndex(v2 => (v2.timestamp === v.timestamp)) === i)
          console.log(reduceData)
          resolve(reduceData)
        }
      } catch (error) {
        reject(error)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
