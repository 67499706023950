
import { getDepartments, getBadgeFromDept, postBadge, deleteBadge, getDepartmentLocation, getRooms, postDepartmentLocation, postDepartment, deleteDepartmentLocation, getDepartmentEmail, postDepartmentEmail, deleteDepartmentEmail, patchDepartment, getLocation, postLocation, patchLocation, getInternalRooms } from '@/api/'

const state = () => ({
  department: {},
  departmentFilter: [],
  departments: [],
  badges: [],
  rooms: [],
  roomsKontakt: [],
  location: {},
  locationFilter: {
    roomId: '',
    buildingId: '',
    isCheckinPoint: '',
    isCheckoutPoint: '',
    active: ''
  },
  departmentTable: {},
  departmentTableOptions: {},
  defaultdepartmentTableOptions: {},
  departmentTableFilter: {
    departmentId: '',
    departmentName: '',
    active: ''
  },
  departmentEmail: {},
  departmentEmailFilter: {
    departmentEmailId: '',
    departmentId: '',
    email: ''
  },
  departmentEmailOptions: {},
  departmentEmailDefaultOptions: {}
})

const mutations = {
  SET_LOCATION (state, data) {
    state.location = data
  },
  SET_DEPARTMENT_TABLE (state, data) {
    state.departmentTable = data
  },
  SET_DEPARTMENT_TABLE_OPTIONS (state, data) {
    state.departmentTableOptions = data
  },
  SET_DEPARTMENT_TABLE_OPTIONS_DEFAULT (state, data) {
    state.defaultdepartmentTableOptions = data
  },
  SET_DEPARTMENT_TABLE_FILTER (state, { element, value }) {
    state.departmentTableFilter[element] = value
  },
  SET_DEPARTMENT (state, { element, value }) {
    state.department[element] = value
  },
  SET_DEPARTMENT_EMAIL (state, data) {
    state.departmentEmail = data
  },
  SET_DEPARTMENT_EMAIL_OPTIONS (state, data) {
    state.departmentEmailOptions = data
  },
  SET_DEPARTMENT_EMAIL_DEFAULT_OPTIONS (state, data) {
    state.departmentEmailDefaultOptions = data
  },
  SET_DEPARTMENTS_LIST (state, data) {
    state.departments = data
  },
  SET_DEPARTMENTS_FILTER (state, data) {
    state.departmentFilter = data
  },
  SET_CURRENT_DEPARTMENT (state, data) {
    state.department = data
  },
  SET_BADGE_LIST (state, data) {
    data.forEach(element => {
      element.loading = false
      element.disabled = false
    })
    state.badges = data
  },
  SET_BADGE_ITEM_LOADING (state, { item, loading }) {
    state.badges[state.badges.indexOf(item)].loading = loading
  },
  SET_ROOM_LIST (state, data) {
    state.rooms = data
  },
  SET_ROOM_KONTAKT (state, data) {
    state.roomsKontakt = data
  },
  SET_DEPARTMENT_EMAIL_FILTER (state, { element, value }) {
    state.departmentEmailFilter[element] = value
  }
}

const actions = {
  GET_LOCATION ({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getLocation({ tenantId: rootState.User.tenant.tenantId, roomId: state.locationFilter.roomId, buildingId: state.locationFilter.buildingId, isCheckinPoint: state.locationFilter.isCheckinPoint, isCheckoutPoint: state.locationFilter.isCheckoutPoint, active: state.locationFilter.active }, message => {
          commit('SET_LOCATION', message.data.items)
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        getInternalRooms({ tenantId: rootState.User.tenant.tenantId }, message => {
          message.data.value.forEach(element => {
            element.roomName = element.name
            element.roomId = element.id
          })
          commit('SET_LOCATION', message.data.value)
          resolve(message.data.value)
        }, error => {
          reject(error)
        })
      }
    })
  },
  SET_CURRENT_DEPARTMENT ({ commit }, data) {
    commit('SET_CURRENT_DEPARTMENT', data)
  },
  GET_DEPARTMENT_EMAIL ({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      getDepartmentEmail({ tenantId: rootState.User.tenant.tenantId, departmentId: state.departmentEmailFilter.departmentId, email: state.departmentEmailFilter.email, departmentEmailId: state.departmentEmailFilter.departmentEmailId, pageSize: state.departmentEmailOptions.itemsPerPage, pageNo: state.departmentEmailOptions.page, sortBy: state.departmentEmailOptions.sortBy, sortDesc: state.departmentEmailOptions.sortDesc }, message => {
        commit('SET_DEPARTMENT_EMAIL', message.data)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  SET_DEPARTMENT_EMAIL_FILTER ({ commit }, { element, value }) {
    commit('SET_DEPARTMENT_EMAIL_FILTER', { element: element, value: value })
  },
  SET_DEPARTMENT_EMAIL_OPTIONS ({ commit, dispatch }, options) {
    commit('SET_DEPARTMENT_EMAIL_OPTIONS', options)
    return new Promise((resolve, reject) => {
      dispatch('Departments/GET_DEPARTMENT_EMAIL', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  APPLY_DEPARTMENT_EMAIL_FILTER ({ commit, state, dispatch }) {
    commit('SET_DEPARTMENT_EMAIL_OPTIONS', state.defaultOptions)
    return new Promise((resolve, reject) => {
      dispatch('Departments/GET_DEPARTMENT_EMAIL', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  SET_DEPARTMENT_EMAIL_DEFAULT_OPTIONS ({ commit }, options) {
    commit('SET_DEPARTMENT_EMAIL_DEFAULT_OPTIONS', options)
  },
  DELETE_EMAIL ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      deleteDepartmentEmail({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  ADD_EMAIL ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postDepartmentEmail({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_DEPARTMENTS ({ commit, state, rootState }, options) {
    const { sortBy, sortDesc, page, itemsPerPage } = options
    return new Promise((resolve, reject) => {
      if (state.departments.length === 0) {
        getDepartments({ tenantId: rootState.User.tenant.tenantId, departmentId: '', departmentName: '', pageSize: itemsPerPage, pageNo: page, sortBy: sortBy, sortDesc: sortDesc }, message => {
          commit('SET_DEPARTMENTS_LIST', message.data.items)
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        resolve()
      }
    })
  },
  GET_DEPARTMENTS_FROM_ROOMID ({ commit, rootState }, options) {
    const { roomId } = options
    return new Promise((resolve, reject) => {
      getDepartmentLocation({ tenantId: rootState.User.tenant.tenantId, departmentId: '', departmentName: '', pageSize: -1, pageNo: 1, sortBy: '', sortDesc: '', active: '1', roomId: roomId, roomName: '' }, message => {
        resolve(message.data.items)
      }, error => {
        reject(error)
      })
    })
  },
  GET_DEPARTMENT_TABLE ({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      getDepartments({ tenantId: rootState.User.tenant.tenantId, departmentId: state.departmentTableFilter.departmentId, departmentName: state.departmentTableFilter.departmentName, pageSize: state.departmentTableOptions.itemsPerPage, pageNo: state.departmentTableOptions.page, sortBy: state.departmentTableOptions.sortBy, sortDesc: state.departmentTableOptions.sortDesc }, message => {
        commit('SET_DEPARTMENT_TABLE', message.data)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  SET_DEPARTMENT_TABLE_OPTIONS ({ commit, dispatch }, options) {
    commit('SET_DEPARTMENT_TABLE_OPTIONS', options)
    return new Promise((resolve, reject) => {
      dispatch('Departments/GET_DEPARTMENT_TABLE', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  SET_DEPARTMENT_TABLE_OPTIONS_DEFAULT ({ commit, dispatch }, options) {
    commit('SET_DEPARTMENT_TABLE_OPTIONS_DEFAULT', options)
    return new Promise((resolve, reject) => {
      dispatch('Departments/GET_DEPARTMENT_TABLE', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  APPLY_DEPARTMENT_TABLE_FILTER ({ commit, state, dispatch }) {
    commit('SET_OPTIONS', state.defaultdepartmentTableOptions)
    return new Promise((resolve, reject) => {
      dispatch('Departments/GET_DEPARTMENT_TABLE', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  GET_DEPARTMENT ({ commit, rootState }, departmentId) {
    return new Promise((resolve, reject) => {
      getDepartments({ tenantId: rootState.User.tenant.tenantId, departmentId: departmentId, departmentName: '', pageSize: -1, pageNo: 1, sortBy: '', sortDesc: false }, message => {
        if (message.data.items.length > 0) {
          commit('SET_CURRENT_DEPARTMENT', message.data.items[0])
        } else {
          commit('SET_CURRENT_DEPARTMENT', {})
        }
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_DEPARTMENTS_FILTER ({ commit, state, rootState }, options) {
    const { sortBy, sortDesc, page, itemsPerPage } = options
    return new Promise((resolve, reject) => {
      if (state.departmentFilter.length === 0) {
        getDepartments({ tenantId: rootState.User.tenant.tenantId, departmentId: '', departmentName: '', pageSize: itemsPerPage, pageNo: page, sortBy: sortBy, sortDesc: sortDesc }, message => {
          commit('SET_DEPARTMENTS_FILTER', message.data.items)
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        resolve()
      }
    })
  },
  GET_BADGE_FROM_DEPT ({ commit, rootState }, options) {
    const { sortBy, sortDesc, page, itemsPerPage, departmentId } = options
    return new Promise((resolve, reject) => {
      getBadgeFromDept({ tenantId: rootState.User.tenant.tenantId, departmentId: departmentId, departmentName: '', pageSize: itemsPerPage, pageNo: page, sortBy: sortBy, sortDesc: sortDesc, active: '1' }, message => {
        commit('SET_BADGE_LIST', message.data.items)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  POST_BADGE ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postBadge({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  DELETE_BADGE ({ commit, rootState }, data) {
    commit('SET_BADGE_ITEM_LOADING', { item: data, loading: true })
    return new Promise((resolve, reject) => {
      deleteBadge({ tenantId: rootState.User.tenant.tenantId, departmentBeaconId: data.departmentBeaconId }, message => {
        commit('SET_BADGE_ITEM_LOADING', { item: data, loading: false })
        resolve(message)
      }, error => {
        commit('SET_BADGE_ITEM_LOADING', { item: data, loading: false })
        reject(error)
      })
    })
  },
  GET_ROOM_LIST ({ commit, rootState }, options) {
    const { departmentId } = options
    return new Promise((resolve, reject) => {
      getDepartmentLocation({ tenantId: rootState.User.tenant.tenantId, departmentId: departmentId, departmentName: '', pageSize: -1, pageNo: 1, sortBy: '', sortDesc: '', active: '1', roomId: '', roomName: '' }, message => {
        commit('SET_ROOM_LIST', message.data.items)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  GET_KONTAKT_ROOM ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getRooms({ tenantId: rootState.User.tenant.tenantId, roomId: '' }, message => {
          commit('SET_ROOM_KONTAKT', message.data.content)
          resolve(message.data.content)
        }, error => {
          reject(error)
        })
      } else {
        getInternalRooms({ tenantId: rootState.User.tenant.tenantId }, message => {
          commit('SET_ROOM_KONTAKT', message.data.value)
          resolve(message.data.value)
        }, error => {
          reject(error)
        })
      }
    })
  },
  KONTAKT_ROOM_MAPPING ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postDepartmentLocation({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  DELETE_ROOM ({ commit, rootState }, options) {
    return new Promise((resolve, reject) => {
      deleteDepartmentLocation({ tenantId: rootState.User.tenant.tenantId, departmentLocationId: options.departmentLocationId }, message => {
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  CREATE_DEPARTMENT ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      postDepartment({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  UPDATE_DEPARTMENT ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      patchDepartment({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  UPDATE_LOCATION ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      patchLocation({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        resolve(message.data)
      }, error => {
        reject(error)
      })
    })
  },
  SYNC_LOCATION ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        postLocation({ tenantId: rootState.User.tenant.tenantId }, message => {
          resolve(message.data)
        }, error => {
          reject(error)
        })
      } else {
        resolve({})
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
