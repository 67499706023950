<template>
  <v-card>
    <v-card-title>เลือกผู้เช่า</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <Select :items="tenantList" :value="tenant" @change="updateData($event)" item-text="tenantName" item-value="tenantId" return-object />
        </v-col>
      </v-row>
     </v-card-text>
      <v-card-actions>
        <v-col cols="6"><PrimaryButton  outlined title="ยกเลิก" block @click="$emit('onClose')"/></v-col>
        <v-col cols="6"><PrimaryButton  title="บันทึก" block @click="applyData()"/></v-col>
      </v-card-actions>
  </v-card>
</template>

<script>
import { stop, initSignalR } from '@/plugins/signalr'
import PrimaryButton from '@/components/common/PrimaryButton'
import Select from '@/components/common/Select'
export default {
  components: {
    PrimaryButton,
    Select
  },
  computed: {
    tenantList () {
      return this.$store.state.User.user.tenant
    },
    tenant: {
      get () {
        return this.$store.state.User.tenant
      },
      set (value) {
        this.$store.commit('User/SET_TENANT', value)
      }
    }
  },
  data () {
    return {
      selectTenant: null
    }
  },
  methods: {
    updateData (item) {
      this.selectTenant = item
    },
    async applyData () {
      this.$store.commit('User/SET_TENANT', this.selectTenant)
      await stop()
      initSignalR()
      this.$emit('onClose')
    }
  }
}
</script>

<style>

</style>