import Vue from 'vue'
import VueRouter from 'vue-router'
import Failed from '../views/Failed.vue'
import MainLayout from '@/layout/MainLayout.vue'
import { registerGuard } from './guard'
import store from '@/store'
import NProgress from 'nprogress'
NProgress.configure({ showSpinner: false })

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'MainLayout',
    component: MainLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
        meta: {
          requiresAuth: true,
          scrollToTop: true
        }
      },
      {
        path: '/gateways',
        name: 'Gateways',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "gateway" */ '../views/GatewayLists.vue')
      },
      {
        path: '/gateways/:id',
        component: () => import(/* webpackChunkName: "gateway" */ '../views/Gateway.vue'),
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: '',
            name: 'GatewayInformation',
            component: () => import(/* webpackChunkName: "gateway" */ '../components/gateway/GatewayInformation.vue'),
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/beacons',
        name: 'Beacons',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "beacon" */ '../views/BeaconLists.vue')
      },
      {
        path: '/beacons/:id',
        component: () => import(/* webpackChunkName: "beacon" */ '../views/Beacon.vue'),
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'information',
            name: 'BeaconInformation',
            component: () => import(/* webpackChunkName: "beacon" */ '../components/beacon/BeaconInformation.vue'),
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'history',
            name: 'BeaconHistory',
            component: () => import(/* webpackChunkName: "beacon" */ '../components/beacon/BeaconHistory.vue'),
            meta: {
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/members',
        name: 'Members',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "members" */ '../views/Members.vue')
      },
      {
        path: '/assets',
        name: 'Asset',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "asset" */ '../views/AssetLists.vue')
      },
      {
        path: '/assets/:id',
        name: 'AssetInformation',
        component: () => import(/* webpackChunkName: "assetID" */ '../views/Asset.vue'),
        meta: {
          requiresAuth: true,
          scrollToTop: true
        }
      },
      {
        path: '/departments',
        name: 'Departments',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "department" */ '../views/DepartmentList.vue')
      },
      {
        path: '/departments/:id',
        name: 'DepartmentInformation',
        component: () => import(/* webpackChunkName: "department" */ '../views/Department.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/booking',
        name: 'Booking',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "booking" */ '../views/Booking.vue')
      },
      {
        path: '/troubleshooting',
        name: 'Troubleshooting',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "troubleshooting" */ '../views/Troubleshooting.vue')
      },
      {
        path: '/distribute',
        name: 'Distribute',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "distribute" */ '../views/Distribute.vue')
      },
      {
        path: '/report',
        name: 'Report',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "report" */ '../views/Report.vue')
      },
      {
        path: '/analytics',
        name: 'Analytics',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "analytics" */ '../views/Analytics.vue')
      },
      {
        path: '/about',
        name: 'About',
        meta: {
          requiresAuth: true
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      }
    ]
  },
  {
    path: '/failed',
    name: 'Failed',
    component: Failed
  },
  {
    path: '/auth',
    name: 'auth',
    component: Failed
  },
  {
    path: '/mobile',
    name: 'MobileLayout',
    component: () => import(/* webpackChunkName: "mobile" */ '@/layout/MobileLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/mobile',
        component: () => import(/* webpackChunkName: "mobilehome" */ '../views/MobileBeacon.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/mobile/tenant/:id',
        name: 'MobileSelectTenant',
        component: () => import(/* webpackChunkName: "mobilehome" */ '../views/MobileSelectTenant.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/mobile/beacons/:id',
        name: 'MobileHome',
        component: () => import(/* webpackChunkName: "mobilebeacon" */ '../views/MobileBeacon.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/mobile/ref/:id',
        name: 'MobileOperation',
        component: () => import(/* webpackChunkName: "mobilebeacon" */ '../views/MobileAssetOperation.vue'),
        beforeEnter (routeTo, routeFrom, next) {
          NProgress.start()
          store.dispatch('MobileAsset/GET_ASSET', routeTo.params.id).then(() => {
            NProgress.done()
            next()
          }).catch(() => {
            NProgress.done()
            if (store.state.User.user.tenant.length > 0) {
              next({ name: 'MobileSelectTenant', params: { id: routeTo.params.id } })
            }
            // if (store.state.User.tenant.tenantId)
            // next()
          })
        },
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/invite/:id',
    name: 'Invitation',
    component: () => import(/* webpackChunkName: "mobile" */ '@/layout/InvitationLayout.vue'),
    meta: {
      requiresAuth: false
    }
  }
]

// scrollBehavior:
// - only available in html5 history mode
// - defaults to no scroll behavior
// - return false to prevent scroll
const scrollBehavior = function (to, from, savedPosition) {
  if (savedPosition) {
    // savedPosition is only available for popstate navigations.
    // return savedPosition
    return new Promise(resolve => {
      if (savedPosition) {
        // create an Observer instance
        const resizeObserver = new ResizeObserver(entries => {
          console.log(savedPosition.y)
          console.log(entries[0].target.clientHeight)
          if (entries[0].target.clientHeight >= savedPosition.y) {
            console.log('observe fire')
            resolve(savedPosition)
            resizeObserver.disconnect()
          }
        })
        // start observing a DOM node
        resizeObserver.observe(document.body)
      } else {
        resolve({ top: 0 })
      }
    })
  } else {
    const position = {}

    // scroll to anchor by returning the selector
    if (to.hash) {
      position.selector = to.hash

      // specify offset of the element
      if (to.hash === '#anchor2') {
        position.offset = { y: 100 }
      }

      // bypass #1number check
      if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
        return position
      }

      // if the returned position is falsy or an empty object,
      // will retain current scroll position.
      return false
    }

    return new Promise(resolve => {
      // check if any matched route config has meta that requires scrolling to top
      if (to.matched.some(m => m.meta.scrollToTop)) {
        // coords will be used if no selector is provided,
        // or if the selector didn't match any element.
        position.x = 0
        position.y = 0
      }
      // wait for the out transition to complete (if necessary)
      this.app.$root.$once('triggerScroll', () => {
        // if the resolved position is falsy or an empty object,
        // will retain current scroll position.
        resolve(position)
      })
    })
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes
})

registerGuard(router)

export default router
