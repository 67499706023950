
import { getDevice, getInternalGateway } from '@/api/'

const state = () => ({
  gateway: {},
  gateways: {},
  options: {},
  uniqueId: ''
})

const mutations = {
  SET_GATEWAYS_LIST (state, data) {
    state.gateways.items = data.devices
    state.gateways.total = data.searchMeta.count
  },
  SET_GATEWAYS_INTERNAL_LIST (state, data) {
    state.gateways.items = data
    state.gateways.total = data.length
  },
  SET_CURRENT_GATEWAY (state, data) {
    state.gateway = data
  },
  SET_GATEWAY_LIST_OPTION (state, data) {
    state.options = { ...state.options, ...data }
  },
  SET_UNIQUEID (state, data) {
    state.uniqueId = data
  }
}

const actions = {
  SET_CURRENT_GATEWAY ({ commit }, data) {
    commit('SET_CURRENT_GATEWAY', data)
  },
  GET_ALL_GATEWAYS ({ commit, rootState, state }) {
    return new Promise((resolve, reject) => {
      const { itemsPerPage, page } = state.options
      const startIndex = (itemsPerPage * (page - 1))
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getDevice({ tenantId: rootState.User.tenant.tenantId, deviceType: 'GATEWAY', startIndex: startIndex, maxResult: (itemsPerPage === -1) ? 500 : itemsPerPage, uniqueId: state.uniqueId }, message => {
          commit('SET_GATEWAYS_LIST', message.data)
          resolve(message)
        }, error => {
          reject(error)
        })
      } else {
        getInternalGateway({ tenantId: rootState.User.tenant.tenantId }, message => {
          console.log(message.data.value)
          commit('SET_GATEWAYS_INTERNAL_LIST', message.data.value)
          resolve(message)
        }, error => {
          reject(error)
        })
      }
    })
  },
  SET_OPTIONS ({ commit, dispatch }, options) {
    commit('SET_GATEWAY_LIST_OPTION', options)
    return new Promise((resolve, reject) => {
      dispatch('Gateways/GET_ALL_GATEWAYS', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
