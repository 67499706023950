<template>
  <v-card flat>
    <v-card-text
      class="natural"
      id="self-checkout-smartbadge-press"
    >
      <div class="text-h4 text-center pa-4 font-weight-bold">
        <p > {{ `หน่วยงาน ${activeOrder.departmentName}` }}  หมายเลขการจอง {{ activeOrder.bookingId }}</p>
        รายการของท่าน {{ activeOrder.assetTypeNameTH }} จำนวน {{ activeOrder.amount }} เครื่อง
        <v-row v-if="confirmQty === activeOrder.amount">
          <v-spacer />
          <v-col md="auto">
            <p class="text-h4 text-center pa-4">
              กรุณากดที่ปุ่ม<strong class="blue--text text--lighten-2">สีฟ้า</strong>บนตัวเครื่องเพื่อยืนยันหมายเลขอุปกรณ์
            </p>
          </v-col>
          <v-col md="auto">
            <v-img
              width="75"
              contain
              class="mx-auto"
              :src="require('@/assets/assettag2.png')"
            />
          </v-col>
          <v-spacer />
        </v-row>
        <p
          v-else-if="confirmQty == 0"
          class="text-h4 text-center pa-4"
        >
          {{ counter }}
        </p>
        <p
          v-else
          class="text-h4 text-center pa-4 red--text text--lighten-1"
        >
          ยังขาดอีก {{ confirmQty }} ตัว
        </p>
      </div>
      <v-row>
        <v-col
          v-for="(item,index) in assets"
          :key="index"
        >
          <DeviceCard v-bind="item" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import DeviceCard from '@/components/checkout/DeviceCard'

export default {
  components: {
    DeviceCard
  },
  props: {
    counter: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState('CheckOut', ['activeOrder', 'assets', 'confirmQty'])
  }
}
</script>

<style scoped>
.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>
