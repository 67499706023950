import { LogLevel, PublicClientApplication } from '@azure/msal-browser'

const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0

export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1A_AST_SIGNUP_SIGNIN', // B2C_1_ASSET_TRACKING'
    forgotPassword: 'B2C_1A_AST_PASSWORDRESET',
    editProfile: 'B2C_1A_SNWEB_ProfileEdit'
  },
  authorities: {
    signUpSignIn: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_AST_SIGNUP_SIGNIN' // B2C_1A_TOU_RBAC_SUSI_ASSET_TRACK' B2C_1A_AST_SIGNUP_SIGNIN // https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_TOU_SUSI //https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_susi
    },
    forgotPassword: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_AST_PASSWORDRESET'
    },
    invitation: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_AST_SIGNUP_INVITATION'
    },
    editProfile: {
      authority:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_SNWEB_ProfileEdit'
    },
    changePassword: {
      authorities:
        'https://nextervwell.b2clogin.com/nextervwell.onmicrosoft.com/B2C_1A_AST_PASSWORDCHANGE'
    }
  },
  authorityDomain: 'nextervwell.b2clogin.com'
}

// eslint-disable-next-line no-unused-vars
export const apiConfig = {
  b2cScopes: [
    'https://nextervwell.onmicrosoft.com/e025ed42-06a2-425b-86e2-ec83ef4cd4ee/read'
  ],
  webApi: 'https://fabrikamb2chello.azurewebsites.net/hello'
}

const msalConfig = {
  auth: {
    clientId: 'e025ed42-06a2-425b-86e2-ec83ef4cd4ee', // 2fdd06f3-7b34-49a3-a78b-0cf1dd87878e// This is the ONLY mandatory field everything else is optional.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: window.location.origin, // window.location.origin, // window.location.origin, // You must register this URI on Azure Portal/App Registration. Defaults to 'window.location.href'.
    postLogoutRedirectUri: window.location.origin, // window.location.origin, // window.location.origin
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'sessionStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO.
    storeAuthStateInCookie: isIE || isEdge // If you wish to store cache items in cookies as well as browser cache, set this to 'true'.
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
        }
      },
      logLevel: LogLevel.Verbose
    }
  }
}

export const msalInstance = new PublicClientApplication(msalConfig)
