<template>
  <v-card>
   <v-card-text class="text-center mx-auto">
    <div class="primary--text text--darken-4 text-h5">พบปัญหาการใช้งานติดต่อ</div>
    <div class="secondary--text text-title-3">Line @vwell</div>
   </v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>

<style>

</style>