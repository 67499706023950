<template>
  <v-app>
    <v-main>
      <v-container fill-height>
        <v-row>
          <v-spacer />
          <v-col cols="auto">
            <v-row dense>
              <v-col cols="auto">
                <div>
              <v-avatar
                tile
                size="150"
              >
                <v-img
                  contain
                  :src="require('@/assets/logo-text.png')"
                />
              </v-avatar>
                </div>
                <div class="text-center" style="top: -30px; position: relative;">
                   <v-avatar
                tile
                size="60"
              >
                   <v-img
                  contain
                  :src="require('@/assets/Ellipsis-1.1s-108px.svg')"
                />
                   </v-avatar>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer/>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {

}
</script>

<style>

</style>
