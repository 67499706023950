<template>
  <v-card class="natural fill-height pa-2 ma-2" flat>
    <v-card-title class="text-h3 text-center font-weight-bold">
      <v-spacer />
      ศูนย์เครื่องมือแพทย์ พร้อมให้บริการ
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-spacer />
        <v-col md="auto">
          <v-card
            class="mx-auto pulse mt-2"
            id="self-checkout-popup-show"
          >
            <v-img
              contain
              height="500px"
              :src="require('@/assets/smartbadge.png')"
            />
            <v-card-title class="text-h4 font-weight-bold">
              <v-spacer />
              กรุณากดปุ่มสีฟ้าบนบัตรของท่าน
              <v-spacer />
            </v-card-title>
            <v-card-actions class="text-h5 error--text">
              <v-spacer />  หากไม่ได้นำบัตรมากรุณาติดต่อ เจ้าหน้าที่ <v-spacer />
            </v-card-actions>
          </v-card>
        </v-col>
        <v-spacer />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>

<style scoped>
.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
</style>
