<template>
  <v-navigation-drawer
    v-bind:value="drawer"
    @input="$emit('update:drawer',$event)"
    prominent
    app
    color="natural darken-4"
    :mini-variant="mini"
    :mini-variant-width="58"
  >
    <v-list
      dense
      nav
    >
      <v-list-item color="natural"  v-on="(user.tenant.length > 1) ? { click: openDialog } : {}">
        <v-list-item-avatar :size="(mini)? 30: 50">
          <v-img
            contain
            :src="require('@/assets/siteavatar.svg')"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            class="title text-title-2 natural--text text--lighten-5 text-wrap pt-2"
          >
            {{ tenant.departmentName }}
          </v-list-item-title>
          <v-list-item-subtitle class="natural--text text--lighten-5 text-wrap pt-2">
            {{ tenant.tenantName }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- <v-divider class="natural lighten-5"></v-divider> -->
    <v-divider class="natural darken-2" />
    <!-- <v-list
     v-if="!mini"
      nav
      expand
      dense
    >
      <v-list-item-group
        v-model="currentRoute"
        active-class="primary"
        aria-label="Navigation"
      >
        <template v-for="item in menuItems">
          <v-list-item
            v-if="!item.childs"
            :key="item.title"

            :to="item.route"
          >
            <v-list-item-content>
              <v-list-item-title
                class="text-title-4 natural--text text--lighten-5"
              >
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-else
            :key="item.title"
            no-action
            :value="true"
          >
            <template v-slot:activator>
              <v-list
                two-line
                dense
              >
                <v-list-item-content>
                  <v-list-item-title class="pt-2 text-title-3 natural--text text--lighten-5">
                    {{ item.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="natural--text text--lighten-5">
                    {{ item.subtitle }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list>
            </template>
            <v-list-item
              v-for="subitem in item.childs"
              :key="subitem.title"
              link
              :to="{name: subitem.route}"
              class="pl-2"
            >
              <v-list-item-icon class="mr-2">
                <v-icon color="natural lighten-5">
                  {{ subitem.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="text-title-4 natural--text text--lighten-5 pa-1"
                >
                  {{ subitem.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-divider
            :key="item.title + 'divider'"
            class="natural darken-2"
          />
        </template>
      </v-list-item-group>
    </v-list> -->

<v-list nav v-if="!mini"  aria-label="Navigation">
      <template  v-for="(item,index) in menu">
        <v-list-item
              :key="index"
              link
              :to="(item.route === '/')? '/' : {name: item.route}"
              class="pl-2"
              active-class="primary"
              @click="resetFilter()"
            >
             <v-list-item-icon class="mr-2">
                <v-icon color="natural lighten-5">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="text-title-4 natural--text text--lighten-5 pa-1"
                >
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
    <v-list nav dense v-else  aria-label="Navigation">
       <v-list-item
          v-for="(item,index) in menu"
          :key="index"
          :to="(item.route === '/')? '/' : {name: item.route}"
          link
          active-class="primary"
        >
          <v-list-item-icon>
            <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
            <v-icon color="natural lighten-5" v-bind="attrs"
          v-on="on">{{ item.icon }}</v-icon>
      </template>
         <span>{{item.title}}</span>
    </v-tooltip>
          </v-list-item-icon>
        </v-list-item>
    </v-list>
    <template v-slot:append>
       <v-dialog v-model="selectTenantDialog" width="384" persistent>
    <SelectTenantDialog :key="selectTenantDialog" @onClose="selectTenantDialog = false"/>
       </v-dialog>
      <v-divider class="natural darken-2" />
      <v-row>
        <v-col v-if="!mini">
         <small class="ml-2 white--text">Build {{version}}</small>
        </v-col>
        <v-col cols="auto">
          <v-btn
            icon
            @click.stop="mini = !mini"
            aria-label="Expanded Navigation"
          >
            <transition name="fade">
              <v-icon
                v-if="!mini"
                color="natural lighten-5"
              >
              {{chevronLeft}}
              </v-icon>
              <v-icon
                v-else
                color="natural lighten-5"
              >
              {{chevronRight}}
              </v-icon>
            </transition>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'
import { mdiStamper, mdiViewDashboard, mdiBookMultiple, mdiBookshelf, mdiPoll, mdiAccountCircle, mdiSitemap, mdiTagMultiple, mdiAntenna, mdiChevronLeft, mdiChevronRight, mdiWrench, mdiChartTimelineVariantShimmer, mdiTable } from '@mdi/js'
import SelectTenantDialog from '@/components/tenant/SelectTenantDialog'
export default {
  name: 'nav-bar',
  components: {
    SelectTenantDialog
  },
  props: {
    drawer: {
      type: Boolean
    }
  },
  data () {
    return {
      // drawer: true,
      chevronLeft: mdiChevronLeft,
      chevronRight: mdiChevronRight,
      chartTimelineVariantShimmer: mdiChartTimelineVariantShimmer,
      version: process.env.VUE_APP_VERSION,
      mode: process.env.VUE_APP_MODE,
      mini: false,
      currentRoute: null,
      selectTenantDialog: false,
      miniMenuItems: [
        { title: 'หน้าแรก', icon: mdiViewDashboard, route: '/' },
        { title: 'บันทึก ยืม-คืน', icon: mdiStamper, route: 'Distribute' },
        { title: 'บันทึก จอง', icon: mdiBookMultiple, route: 'Booking' },
        { title: 'บันทึก แจ้งปัญหา', icon: mdiWrench, route: 'Troubleshooting' },
        { title: 'เครื่องมือแพทย์', icon: mdiBookshelf, route: 'Asset' },
        { title: 'รายงาน', icon: mdiTable, route: 'Report' },
        { title: 'วิเคราะห์', icon: mdiChartTimelineVariantShimmer, route: 'Analytics' },
        { title: 'ผู้ใช้งาน', icon: mdiAccountCircle, route: 'Members' },
        { title: 'หน่วยงาน', icon: mdiSitemap, route: 'Departments' },
        { title: 'บีคอน', icon: mdiTagMultiple, route: 'Beacons' },
        { title: 'เกตเวย์', icon: mdiAntenna, route: 'Gateways' }
      ],
      menuItems: [
        {
          title: 'หน้าแรก',
          subtitle: 'เครื่องมือแพทย์',
          icon: 'mdi-text-box-search-outline',
          route: 'Home'
        },
        {
          title: 'ยืม-คืน',
          subtitle: 'บันทึกการให้ยืมเครื่องมือแพทย์',
          icon: 'mdi-text-box-search-outline',
          childs: [
            { title: 'บันทึก ยืม-คืน', icon: mdiStamper, route: 'Distribute' },
            { title: 'บันทึก จอง', icon: mdiBookMultiple, route: 'Booking' }

          ]
        },
        {
          title: 'เครื่องมือแพทย์',
          subtitle: 'รายการเครื่องมือแพทย์หมุนเวียน',
          icon: 'mdi-text-box-search-outline',
          childs: [
            { title: 'เครื่องมือแพทย์', icon: mdiBookshelf, route: 'Asset' }
          ]
        },
        {
          title: 'รายงาน',
          subtitle: 'สรุปข้อมูลในรูปแบบรายงาน',
          icon: 'mdi-text-box-search-outline',
          childs: [
            { title: 'รายงาน', icon: mdiPoll, route: 'Report' }
          ]
        },
        {
          title: 'ตั้งค่า',
          subtitle: 'ผู้ใช้งาน เกตเวย์ บีคอน',
          icon: 'mdi-text-box-search-outline',
          childs: [
            { title: 'ผู้ใช้งาน', icon: mdiAccountCircle, route: 'Members' },
            { title: 'หน่วยงาน', icon: mdiSitemap, route: 'Departments' },
            { title: 'บีคอน', icon: mdiTagMultiple, route: 'Beacons' },
            { title: 'เกตเวย์', icon: mdiAntenna, route: 'Gateways' }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState('User', ['tenant', 'user']),
    menu () {
      if (!this.tenant.roleId.includes('ward')) {
        return [
          { title: 'หน้าแรก', icon: mdiViewDashboard, route: '/' },
          { title: 'บันทึก ยืม-คืน', icon: mdiStamper, route: 'Distribute' },
          { title: 'บันทึก แจ้งปัญหา', icon: mdiWrench, route: 'Troubleshooting' },
          { title: 'เครื่องมือแพทย์', icon: mdiBookshelf, route: 'Asset' },
          { title: 'รายงาน', icon: mdiTable, route: 'Report' },
          { title: 'วิเคราะห์', icon: mdiChartTimelineVariantShimmer, route: 'Analytics' },
          { title: 'ผู้ใช้งาน', icon: mdiAccountCircle, route: 'Members' },
          { title: 'หน่วยงาน', icon: mdiSitemap, route: 'Departments' },
          { title: 'บีคอน', icon: mdiTagMultiple, route: 'Beacons' },
          { title: 'เกตเวย์', icon: mdiAntenna, route: 'Gateways' }
        ]
      } else {
        return [
          { title: 'บันทึก จอง', icon: mdiBookMultiple, route: 'Booking' },
          {title: 'หน้าแรก', icon: mdiViewDashboard, route: '/'}
        ]
      }
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        if (this.$route.name === 'AssetInformation') {
          this.currentRoute = 3
        }
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  },
  methods: {
    change(event) {
      console.log(event)
    },
    openDialog () {
      this.selectTenantDialog = 1
    },
    async resetFilter () {
      try {
        // await this.$store.dispatch('Distribute/RESET_FILTER_NOT_RELOAD')
        // await this.$store.dispatch('Assets/RESET_FILTER_NOT_RELOAD')
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style>

</style>
