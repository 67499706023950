<template>
  <v-card>
    <v-card-title>
    รายการจองเครื่องมือแพทย์ใหม่
    </v-card-title>
    <v-card-text>
        <v-list>
            <v-list-item v-for="(item,index) in bookingRequest" :key="index">
                <v-list-item-avatar size="100">
                    <v-img contain :src="item.image"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                     หน่วยงาน {{item.departmentName}}  จอง {{item.model}} 1 เครื่อง
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{item.assetTypeNameTH}}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-row>
                    <v-btn block :disabled="posting" color="primary" small  @click.stop="accept(item)">รับทราบ</v-btn>
                    <v-btn block :disabled="posting" class="mt-3" color="primary" small outlined @click.stop="reject(item)">ปฏิเสธ</v-btn>
                </v-row>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { EventBus } from '@/plugins/event-bus'
import { ringtone } from '@/plugins/ringtone'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      posting: false
    }
  },
  mounted () {
    EventBus.$on('requestBooking', this.requestBooking)
  },
  async destroyed () {
    EventBus.$off('requestBooking', this.requestBooking)
  },
  computed: {
    ...mapState('Notification', ['bookingRequest'])
  },
  methods: {
    accept (item) {
      const body = {
        approveStatus: 'Y',
        bookingId: item.bookingId,
        departmentId: item.departmentId,
        modelId: item.modelId
      }
      this.stop()
      this.posting = true
      this.$store.dispatch('Notification/REPLY_BOOKING', body).then(() => {
        if (this.bookingRequest.length === 0) {
          EventBus.$emit('bookingUpdate', 'success')
          this.$emit('onClose')
        }
        this.posting = false
      }).catch(() => {
        this.posting = false
        if (this.bookingRequest.length === 0) {
          EventBus.$emit('bookingUpdate', 'fail')
          this.$emit('onClose')
        }
      })
    },
    reject (item) {
      const body = {
        approveStatus: 'N',
        bookingId: item.bookingId,
        departmentId: item.departmentId,
        modelId: item.modelId
      }
      this.stop()
      this.$store.dispatch('Notification/REPLY_BOOKING', body).then(() => {
        if (this.bookingRequest.length === 0) {
          EventBus.$emit('bookingUpdate', 'success')
          this.$emit('onClose')
        }
        this.posting = false
      }).catch(() => {
        this.posting = false
        if (this.bookingRequest.length === 0) {
          EventBus.$emit('bookingUpdate', 'fail')
          this.$emit('onClose')
        }
      })
    },
    getImage (value) {
      const queryOption = {
        modelImage: value
      }
      this.$store.dispatch('Assets/GET_ASSET_IMAGE', queryOption).then((data) => {
        value = data.items.imageUrl
      }).catch(() => {
        value = ''
      })
    },
    play () {
      ringtone.volume = 1
      ringtone.loop = true
      ringtone.play()
    },
    stop () {
      ringtone.pause()
    }
  },
}
</script>

<style>

</style>
