import Vue from 'vue'
import { HubConnectionBuilder, LogLevel, HubConnectionState } from '@microsoft/signalr'
import { EventBus } from '@/plugins/event-bus'
import store from '@/store'
// console.log(signalR)
// https://api.vwell.care/ast/demo/stream
// https://streams-signals.azurewebsites.net/api
var signalRconnection = {}

const initSignalR = async () => {
  // console.log('HubConnectionState.Connected')
  // console.log(signalRconnection.state)
  // console.log(HubConnectionState.Connected)
  if (signalRconnection.state !== HubConnectionState.Connected && signalRconnection.state !== HubConnectionState.Connecting) {
    // console.log('Init Signalr')
    // console.trace()
    signalRconnection = new HubConnectionBuilder()
      .withUrl(store.state.User.tenant.negotiateUrl.replace('/negotiate', ''), {
        accessTokenFactory: async () => {
          const token = await Vue.prototype.$auth.acquireToken()
          return token.accessToken
        }
      })
      .configureLogging(LogLevel.Information).build()
      // .withAutomaticReconnect()

    subscribeForNewMessage(signalRconnection)
    subscribeForReconnecting(signalRconnection)
    subscribeForReconnected(signalRconnection)
    subscribeForOnClose(signalRconnection)
    subscribeForRequestBooking(signalRconnection)
    subscribeForResponseBooking(signalRconnection)
    await start()
  }
}

const start = async () => {
  try {
    if (signalRconnection.state !== HubConnectionState.Connected) {
      await signalRconnection.start()
      console.assert(signalRconnection.state === HubConnectionState.Connected)
      console.log('SignalR Connected.')
    }
  } catch (err) {
    console.assert(signalRconnection.state === HubConnectionState.Disconnected)
    console.log(err)
    setTimeout(() => start(), 5000)
  }
}

const stop = async () => {
  try {
    console.log(signalRconnection)
    await signalRconnection.stop()
    // await signalRconnection.dispose()
    // console.assert(signalRconnection.state === HubConnectionState.Disconnected)
    // console.log('SignalR Disconnected.')
  } catch (err) {
    console.log(err)
  }
}

const sendMessage = async ({ user, message }) => {
  try {
    const ret = await signalRconnection.invoke('sendToUser', user, message)
    console.log(ret)
  } catch (err) {
    console.error(err)
  }
}

const subscribeForRequestBooking = async (connection) => {
  connection.on('requestBooking', (message) => {
    console.log('requestBooking')
    if (!store.state.Notification.disabled) {
      store.dispatch('Notification/SET_BOOKING', message).then(() => {
        EventBus.$emit('requestBooking', message)
      })
    }
    console.log(message)
  })
}

const subscribeForResponseBooking = async (connection) => {
  connection.on('responseBooking', (message) => {
    console.log('responseBooking')
    console.log(message)
    EventBus.$emit('responseBooking', message)
  })
}

const subscribeForNewMessage = async (connection) => {
  connection.on('newMessage', (message) => {
    console.log(message)
    if (!store.state.Notification.disabled) {
      EventBus.$emit('newMessage', message)
      store.dispatch('CheckOut/PROCESS_EVENT', message)
    }
  })
}
const subscribeForReconnecting = async (connection) => {
  connection.onreconnecting(error => {
    console.log('ReConnecting')
    console.assert(connection.state === HubConnectionState.Reconnecting)
    EventBus.$emit('onreconnecting', error)
  })
}

const subscribeForReconnected = async (connection) => {
  connection.onreconnected(connectionId => {
    console.assert(connection.state === HubConnectionState.Connected)
    EventBus.$emit('onreconnected', connectionId)
  })
}

const subscribeForOnClose = async (connection) => {
  connection.onclose(async () => {
    console.log('onclose')
    console.log(connection)
    EventBus.$emit('onclose')
    await start()
  })
}

export {
  initSignalR,
  sendMessage,
  stop
}
