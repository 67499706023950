
// import { getUsers, postUsers, getDepartment, getRole } from '@/api/'
import { getPermission } from '@/api/'
const state = () => ({
  user: {},
  tenant: {},
  permission: null,
  permissionLoading: false,
  roles: null
})

const mutations = {
  SET_USER (state, data) {
    state.user = data
  },
  SET_TENANT (state, data) {
    state.tenant = data
  },
  SET_PERMISSION (state, data) {
    state.permission = data
  },
  SET_PERMISSION_LOADING (state, data) {
    state.permissionLoading = data
  },
  SET_ROLES (state, data) {
    if (data.roleId.includes('ward')) {
      state.roles = 'ward'
    } else {
      state.roles = 'center'
    }
  }
}

const actions = {
  CHECK_PERMISSION ({ commit, state }, { endpoint, method }) {
    return new Promise((resolve) => {
      if (Array.isArray(state.permission)) {
        const hasPermit = state.permission.filter((element) => element.endpoint === endpoint && element.method === method)
        if (hasPermit.length > 0) {
          resolve(true)
        } else {
          resolve(false)
        }
      } else {
        resolve(false)
      }
    })
  },
  GET_PERMISSION ({ commit, rootState }, roleId) {
    return new Promise((resolve, reject) => {
      getPermission({ tenantId: rootState.User.tenant.tenantId, roleId: roleId }, message => {
        commit('SET_PERMISSION', message.data.value)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  SET_USER ({ commit, state, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit('SET_USER', data)
      commit('SET_TENANT', data.tenant[0])
      commit('SET_ROLES', data.tenant[0])
      commit('SET_PERMISSION_LOADING', true)
      dispatch('User/GET_PERMISSION', state.tenant.roleId, { root: true }).then((message) => {
        commit('SET_PERMISSION_LOADING', false)
        resolve(message)
      }).catch((error) => {
        commit('SET_PERMISSION_LOADING', false)
        reject(error)
      })
    })
  },
  SET_TENANT ({ commit }, data) {
    return new Promise((resolve) => {
      commit('SET_TENANT', data)
      commit('SET_ROLES', data)
      resolve(data)
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
