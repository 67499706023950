import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import Beacons from '@/store/modules/beacons'
import Gateways from '@/store/modules/gateways'
import Members from '@/store/modules/members'
import Assets from '@/store/modules/assets'
import Booking from '@/store/modules/booking'
import User from '@/store/modules/user'
import CheckOut from '@/store/modules/checkout'
import Sensor from '@/store/modules/sensor'
import Departments from '@/store/modules/departments'
import Distribute from '@/store/modules/distribute'
import Reports from '@/store/modules/report'
import Troubleshooting from '@/store/modules/troubleshooting'
import MobileAsset from '@/store/modules/mobileAsset'
import Utilization from '@/store/modules/utilization'
import Notification from '@/store/modules/notification'
import Analytics from '@/store/modules/analytics'
import Log from '@/store/modules/log'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ['User', 'Notification']
})

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Beacons,
    Gateways,
    Members,
    Assets,
    Booking,
    User,
    CheckOut,
    Sensor,
    Departments,
    Distribute,
    Reports,
    Troubleshooting,
    MobileAsset,
    Notification,
    Utilization,
    Analytics,
    Log
  },
  plugins: [vuexLocal.plugin]
})
