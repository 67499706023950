/* eslint-disable indent */
import { getAssets, getAssetBeacon } from '@/api/'
const state = () => ({
  asset: {},
  beacon: {},
  assetFilter: {
    assetId: '',
    brandId: '',
    modelId: '',
    supplierId: '',
    assetTypeId: '',
    riskLevel: '',
    statusId: '',
    active: true,
    isPooling: '',
    isTracking: ''
  }
})

const mutations = {
  SET_ASSET (state, data) {
    state.asset = data
  },
  SET_BEACON (state, data) {
    state.beacon = data
  }
}

const actions = {
  GET_ASSET ({ commit, state, rootState }, refId) {
    return new Promise((resolve, reject) => {
        getAssets({ tenantId: rootState.User.tenant.tenantId, assetId: state.assetFilter.assetId, mac: state.assetFilter.mac, pageSize: -1, pageNo: 1, sortBy: '', sortDesc: '', active: state.assetFilter.active, keyword: refId, brandId: state.assetFilter.brandId, modelId: state.assetFilter.modelId, supplierId: state.assetFilter.supplierId, assetTypeId: state.assetFilter.assetTypeId, riskLevel: state.assetFilter.riskLevel, statusId: state.assetFilter.statusId, isPooling: state.assetFilter.isPooling, isTracking: state.assetFilter.isTracking }, message => {
            if (message.data.items.length > 0) {
              commit('SET_ASSET', message.data.items[0])
              resolve(message.data.items[0])
            } else {
              reject(message.data)
            }
          }, error => {
            reject(error)
          })
    })
  },
  GET_ASSET_BEACON ({ commit, state, rootState }, assetId) {
    return new Promise((resolve, reject) => {
      getAssetBeacon({ tenantId: rootState.User.tenant.tenantId, assetId: assetId, mac: '', pageSize: -1, pageNo: 1, sortBy: '', sortDesc: '', active: state.assetFilter.active, keyword: '', brandId: state.assetFilter.brandId, modelId: state.assetFilter.modelId, supplierId: state.assetFilter.supplierId, assetTypeId: state.assetFilter.assetTypeId, riskLevel: state.assetFilter.riskLevel, statusId: state.assetFilter.statusId }, message => {
            if (message.data.items.length > 0) {
              commit('SET_BEACON', message.data.items[0])
              resolve(message.data.items[0])
            } else {
              reject(message.data)
            }
          }, error => {
            reject(error)
          })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
