import { getBooking, patchBooking } from '@/api/'

const state = () => ({
  disabled: false,
  bookingRequest: [],
  filter: {
    bookingId: '',
    trackingId: '',
    brandId: '',
    modelId: '',
    assetTypeId: '',
    departmentId: '',
    startDate: '',
    endDate: ''
  },
  options: {
    itemsPerPage: 10,
    page: 0

  }
})

const mutations = {
  SET_DISABLED (state, data) {
    state.disabled = data
  },
  ADD_BOOKING (state, data) {
    state.bookingRequest.push(data)
  },
  REMOVE_BOOKING (state, data) {
    state.bookingRequest.splice(state.bookingRequest.indexOf(state.bookingRequest.filter(el => el.bookingId === data.bookingId)[0]), 1)
  }
}

const actions = {
  REPLY_BOOKING ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      patchBooking({ tenantId: rootState.User.tenant.tenantId }, data, message => {
        commit('REMOVE_BOOKING', data)
        resolve(message)
      }, error => {
        commit('REMOVE_BOOKING', data)
        reject(error)
      })
    })
  },
  SET_BOOKING ({ commit, state, rootState, dispatch }, data) {
    console.log('Found Event & GET BOOKING')
    return new Promise((resolve, reject) => {
      getBooking({ tenantId: rootState.User.tenant.tenantId, departmentId: state.filter.departmentId, pageSize: 1, pageNo: 1, sortBy: '', sortDesc: '0', bookingId: data.bookingId, brandId: state.filter.brandId, modelId: state.filter.modelId, assetTypeId: state.filter.assetTypeId, trackingId: state.filter.trackingId, startDate: state.filter.startDate, endDate: state.filter.endDate }, message => {
        console.log('COMMIT EVENT')
        console.log(message.data.items[0])
        if (message.data.items.length > 0) {
          const queryOption = {
            modelImage: message.data.items[0].modelImage
          }
          dispatch('Assets/GET_ASSET_IMAGE', queryOption, { root: true }).then((data) => {
            message.data.items[0].image = data.items.imageUrl
            commit('ADD_BOOKING', message.data.items[0])
            resolve(message)
          }).catch(() => {
            message.data.items[0].image = null
            commit('ADD_BOOKING', message.data.items[0])
            resolve(message)
          })
        } else {
          reject(message)
        }
      }, error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
