
import { getBooking, patchBooking, postBooking, deleteBooking, getDistribute, getBulkPosition, getRooms, getAssetStock, postInternalPositions } from '@/api/'

function select (data, key, item) {
  const selectedData = {}
  selectedData[key] = data[key].map(function (d) { return d[item] })
  return selectedData
}

const state = () => ({
  booking: {},
  options: {},
  distributeOptions: {},
  distributes: {},
  defaultOptions: {},
  positions: [],
  rooms: [],
  defaultFilter: {
    bookingId: '',
    trackingId: '',
    brandId: '',
    modelId: '',
    assetTypeId: '',
    departmentId: '',
    startDate: '',
    endDate: ''
  },
  filter: {
    bookingId: '',
    trackingId: '',
    brandId: '',
    modelId: '',
    assetTypeId: '',
    departmentId: '',
    startDate: '',
    endDate: ''
  },
  distributeFilter: {
    distributionId: '',
    bookingId: '',
    brandId: '',
    assetId: '',
    modelId: '',
    isReturn: '0',
    isOverDowntime: '',
    startDate: '',
    endDate: '',
    returnStartDate: '',
    returnEndDate: '',
    assetTypeId: '',
    keyword: ''
  }
})

const mutations = {
  SET_DISTRIBUTE_LIST (state, { distribute, location }) {
    const rooms = []
    location.forEach((element) => {
      state.rooms.forEach((subEl) => {
        if (subEl.id === element.roomId) {
          element = { ...element, ...subEl }
        }
      })
      delete element.id
      rooms.push(element)
    })
    state.positions = rooms
    for (let i = 0; i < distribute.items.length; i++) {
      rooms.forEach((element) => {
        if (element.trackingId === distribute.items[i].mac) {
          distribute.items[i] = { ...distribute.items[i], ...element }
        }
      })
    }
    state.distributes = distribute
  },
  SET_ALL_ROOMS (state, data) {
    state.rooms = data
  },
  SET_BOOKING_LIST (state, data) {
    state.booking = data
  },
  SET_OPTIONS (state, data) {
    state.options = data
  },
  SET_DISTRIBUTE_OPTIONS (state, data) {
    state.distributeOptions = data
  },
  SET_DEFAULT_OPTIONS (state, data) {
    state.defaultOptions = data
  },
  SET_FILTER (state, { element, value }) {
    state.filter[element] = value
  },
  SET_FILTER_TO_DEFAULT (state) {
    state.filter = {
      bookingId: '',
      trackingId: '',
      brandId: '',
      modelId: '',
      assetTypeId: '',
      departmentId: '',
      startDate: '',
      endDate: ''
    }
  }
}

const actions = {
  SET_TABLE_DISTRIBUTE_OPTIONS ({ commit, dispatch }, options) {
    commit('SET_DISTRIBUTE_OPTIONS', options)
    return new Promise((resolve, reject) => {
      dispatch('Booking/GET_ALL_DISTRIBUTE', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  GET_BULK_POSTION ({ commit, state, rootState }, data) {
    const body = {
      trackingId: data
    }
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getBulkPosition({ tenantId: rootState.User.tenant.tenantId }, body, message => {
          resolve(message.data)
        }, error => {
          reject(error)
        })
      } else {
        postInternalPositions({ tenantId: rootState.User.tenant.tenantId }, body, message => {
          resolve(message.data.value)
        }, error => {
          reject(error)
        })
      }
    })
  },
  GET_ALL_DISTRIBUTE ({ commit, state, rootState, dispatch }) {
    return new Promise((resolve, reject) => {
      getDistribute({ tenantId: rootState.User.tenant.tenantId, pageSize: state.distributeOptions.itemsPerPage, pageNo: state.distributeOptions.page, sortBy: (state.distributeOptions.sortBy) ? state.distributeOptions.sortBy : '', sortDesc: (state.distributeOptions.sortDesc) ? '1' : '0', isReturn: state.distributeFilter.isReturn, assetId: state.distributeFilter.assetId, keyword: state.distributeFilter.keyword, distributionId: state.distributeFilter.distributionId, bookingId: state.distributeFilter.bookingId, brandId: state.distributeFilter.brandId, modelId: state.distributeFilter.modelId, borrowDepartmentId: rootState.User.tenant.departmentId, isOverDowntime: state.distributeFilter.isOverDowntime, startDate: state.distributeFilter.startDate, endDate: state.distributeFilter.endDate, returnStartDate: state.distributeFilter.returnStartDate, returnEndDate: state.distributeFilter.returnEndDate, assetTypeId: state.distributeFilter.assetTypeId }, message => {
        const macs = select(message.data, 'items', 'mac')
        dispatch('Distribute/GET_BULK_POSTION', macs.items, { root: true }).then((innerMessage) => {
          commit('SET_DISTRIBUTE_LIST', { distribute: message.data, location: innerMessage })
          resolve(message)
        }).catch((error) => {
          console.log(error)
        })
      }, error => {
        reject(error)
      })
    })
  },
  GET_ALL_ROOM ({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      if (rootState.User.tenant.locationSource === 'kontakt') {
        getRooms({ tenantId: rootState.User.tenant.tenantId, roomId: '' }, message => {
          commit('SET_ALL_ROOMS', message.data.content)
          resolve(message.data.content)
        }, error => {
          reject(error)
        })
      } else {
        console.log('call Moko')
      }
    })
  },
  GET_ALL_BOOKING ({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      getBooking({ tenantId: rootState.User.tenant.tenantId, departmentId: state.filter.departmentId, pageSize: state.options.itemsPerPage, pageNo: state.options.page, sortBy: (state.options.sortBy[0]) ? state.options.sortBy[0] : '', sortDesc: (state.options.sortDesc[0]) ? '1' : '0', bookingId: state.filter.bookingId, brandId: state.filter.brandId, modelId: state.filter.modelId, assetTypeId: state.filter.assetTypeId, trackingId: state.filter.trackingId, startDate: state.filter.startDate, endDate: state.filter.endDate }, message => {
        commit('SET_BOOKING_LIST', message.data)
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  SET_TABLE_OPTIONS ({ commit, dispatch }, options) {
    commit('SET_OPTIONS', options)
    return new Promise((resolve, reject) => {
      dispatch('Booking/GET_ALL_BOOKING', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  SET_TABLE_FILTER ({ commit }, { element, value }) {
    commit('SET_FILTER', { element: element, value: value })
  },
  APPLY_FILTER ({ commit, state, dispatch }) {
    commit('SET_OPTIONS', state.defaultOptions)
    return new Promise((resolve, reject) => {
      dispatch('Booking/GET_ALL_BOOKING', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  RESET_FILTER ({ commit, state, dispatch }) {
    commit('SET_OPTIONS', state.defaultOptions)
    commit('SET_FILTER_TO_DEFAULT')
    return new Promise((resolve, reject) => {
      dispatch('Booking/GET_ALL_BOOKING', {}, { root: true }).then((message) => {
        resolve(message)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  SET_TABLE_DEFAULT_OPTIONS ({ commit }, options) {
    commit('SET_DEFAULT_OPTIONS', options)
  },
  PATCH_BOOKING_STATUS ({ commit }, data) {
    return new Promise((resolve, reject) => {
      patchBooking(data, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  },
  CREATE_BOOKING ({ commit, rootState }, data) {
    return new Promise((resolve, reject) => {
      // getAssetStock
      getAssetStock({ tenantId: rootState.User.tenant.tenantId, modelId: data.modelId, brand: '', model: '', pageSize: 1, pageNo: 1, sortBy: '', sortDesc: '', assetTypeNameTH: '', assetTypeNameEN: '', statusId: '' }, message => {
        if (message.data.items.length > 0) {
          data.assetStockEtag = message.data.items[0].etag
          postBooking({ tenantId: rootState.User.tenant.tenantId }, data, message => {
            resolve(message)
          }, error => {
            reject(error)
          })
        } else {
          reject(message)
        }
      }, error => {
        reject(error)
      })
    })
  },
  DELETE_BOOKING ({ commit, rootState }, param) {
    return new Promise((resolve, reject) => {
      deleteBooking({ tenantId: rootState.User.tenant.tenantId, bookingId: param.bookingId }, message => {
        resolve(message)
      }, error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
