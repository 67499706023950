<template>
      <v-snackbar
         v-bind="$attrs"
         color="natural darken-2"
         v-on="inputListeners"
         :timeout="-1"
      >
      <v-avatar size="30">
        <!-- <v-icon width="25" v-if="online">{{iconWifi}}</v-icon> -->
        <v-icon width="25">{{iconWifiOff}}</v-icon>
      <!-- <v-img width="30" :src="require('@/assets/alertIcon.png')"/> -->
      </v-avatar>
      <!-- <span v-if="online">สามารถเชื่อมต่ออินเตอร์เน็ตได้แล้ว</span> -->
      <span class="ml-8">ไม่สามารถเชื่อมต่ออินเตอร์เน็ต</span>
        <!-- {{$attrs.message}} -->
        <!-- <template v-slot:action="{ attrs }">
          <v-btn
            icon
            v-bind="attrs"
            @click="$emit('onClose')"
          >
            <v-icon>
              {{iconClose}}
           </v-icon>
          </v-btn>
        </template> -->
      </v-snackbar>
</template>

<script>
import { mdiClose, mdiWifiOff, mdiWifi } from '@mdi/js'
export default {
  props: {
    online: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      iconClose: mdiClose,
      iconWifi: mdiWifi,
      iconWifiOff: mdiWifiOff
    }
  },
  computed: {
    inputListeners: function () {
      var vm = this
      // `Object.assign` merges objects together to form a new object
      return Object.assign({},
        // We add all the listeners from the parent
        this.$listeners,
        // Then we can add custom listeners or override the
        // behavior of some listeners.
        {
          // This ensures that the component works with v-model
          input: function (event) {
            vm.$emit('input', event)
          }
        }
      )
    }
  }

}
</script>

<style>

</style>
