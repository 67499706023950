<template>
  <div>
    <router-view v-if="$auth.inProgress === 'none' && !permissionLoading" />
    <ProgressIndicator v-else />
  </div>
</template>

<script>
import ProgressIndicator from '@/components/ProgressIndicator'
export default {
  name: 'App',
  components: {
    ProgressIndicator
  },
  computed: {
    permissionLoading () {
      return this.$store.state.User.permissionLoading
    }
  }
}
</script>
<style>
  #nprogress .bar {
      background: #245DDB !important;
    }
</style>
