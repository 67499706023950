<template>
  <v-menu
    v-model="menuModel"
    max-height="75vh"
    offset-y
    readonly
    @input="resetSearch"
  >
    <template #activator="{ attrs }">
      <v-text-field
        v-model="searchString"
        solo
        v-bind="attrs"
        hide-details
        single-line
        autocomplete="off"
        :label="($vuetify.breakpoint.mobile) ?`${tenant.tenantId.toUpperCase()}-XXXX` : `ค้นหาเครื่องมือแพทย์ ${tenant.tenantId.toUpperCase()}-XXXX`"
        @focus="onFocus"
      >
        <template #prepend-inner>
          <v-icon
            class="ml-1 mr-2"
          >
            {{iconMagnify}}
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-card>
      <v-card-text>
        <v-list>
           <v-list-item-group v-model="selectedItem">
          <v-list-item v-for="(item,index) in searchResult.results" :key="index" @click="onClick(item)">
            <v-list-item-content>
              <v-list-item-title class="wrap--text">
{{item.document.assetTypeNameTH}}
              </v-list-item-title>
                <v-list-item-title>
<span class="font-weight-bold">{{item.document.refId}}</span> {{item.document.model}} 
              </v-list-item-title>
              <v-list-item-subtitle v-html="item.text"/>
            </v-list-item-content>
          </v-list-item>
           </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
import searchMixins from '../../mixins/searchMixins'
import { mdiMagnify } from '@mdi/js'
export default {
  name: 'MasterSearch',
  mixins: [searchMixins],
  data () {
    return {
      isFocused: false,
      menuModel: false,
      searchString: '',
      selectedItem: null,
      iconMagnify: mdiMagnify
    }
  },
  computed: {
    ...mapState('User', ['tenant']),
    isSearching () {
      return this.searchString && this.searchString.length > 0
    }
  },
  watch: {
    isSearching (val) {
      val
        ? this.menuModel = true
        : this.resetSearch()
    },
    searchString (val) {
      if (val) {
        this.suggester(val)
      }
    }
  },
  mounted () {
    this.$watch(
      () => this.$store.state.User.tenant.tenantId,
      () => {
        if (this.$store.state.User.tenant.tenantId) {
          this.changeIndex()
        }
      },
      { immediate: true }
    )
  },
  methods: {
    async onFocus () {
      this.isFocused = true
    },
    resetSearch () {
      this.isFocused = false
      this.selectedItem = null
    },
    onClick (item) {
      this.isFocused = false
      this.selectedItem = null
      this.searchString = ''
      this.$router.push({ name: 'AssetInformation', params: { id: item.document.assetId } })
    }
  }
}
</script>

<style>

</style>
