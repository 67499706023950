import { SearchClient, AzureKeyCredential } from '@azure/search-documents'
import { searchAsset } from '@/api/'
// import client from '@/plugins/search'
import store from '@/store'
const url = 'https://search-ast-prod-sa-01.search.windows.net/'
// const indexName = 'demo-asset-index'
const apiKey = 'B92609BB6228C4D505C83B83FD5238B1'

// let client = new SearchClient(
//   url,
//   store.state.User.tenant.indexName,
//   new AzureKeyCredential(apiKey)
// )

const searchMixins = {
  data () {
    return {
      searchResult: [],
      client: null
    }
  },
  mounted () {
    if (store.state.User.tenant.indexName) {
      this.client = new SearchClient(
        url,
        store.state.User.tenant.indexName,
        new AzureKeyCredential(apiKey)
      )
    }
  },
  methods: {
    changeIndex () {
      this.client = new SearchClient(
        url,
        store.state.User.tenant.indexName,
        new AzureKeyCredential(apiKey)
      )
    },
    async suggester (keyword) {
      if (store.state.User.tenant.indexName) {
        this.searchResult = await this.client.suggest(
          keyword,
          store.state.User.tenant.suggesterName,
          {
            highlightPreTag: '<b>',
            highlightPostTag: '</b>',
            suggesterName: store.state.User.tenant.suggesterName,
            fuzzy: false,
            select: ['assetId', 'sn', 'assetTypeNameTH', 'model', 'modelImage', 'refId']
          })
      } else {
        const ret = await searchAsset({ tenantId: store.state.User.tenant.tenantId, keyword: keyword })
        const results = []
        ret.data.forEach(element => {
          results.push({
            document: element,
            text: ''
          })
        })
        this.searchResult = { results: results }
      }
    }
  }
}

export default searchMixins
